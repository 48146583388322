import first from '../../assets/first.png';
import second from '../../assets/second.png';
import third from '../../assets/third.png';
import one from "../../assets/one.png";
import two from "../../assets/two.png";
import three from "../../assets/three.png";
import { HowWorksSectionContainer, CardContainer, TextSplash } from './how-works.styles';

const HowWorksComponent = () => (<>
  <HowWorksSectionContainer>
    <section className="section section-about lighten-4">
      <div className="container">
        <div className="row purple-text text-darken-1">
          <div className="col s12">
            <div className="col s12 m10 offset-m1 center">
              <h1>
                <b><TextSplash>How do you Givit?</TextSplash></b>
              </h1>
            </div>
          </div>
        </div>
        <div className="row row-1 purple-text text-darken-1">
          <div className="col m6">
            <div className="img-content">
              <h2 style={{ display: "flex" }}><img src={one} alt="" /><b><TextSplash>Build it </TextSplash><br /><TextSplash>E-Card</TextSplash></b></h2>
              <h4><TextSplash>Choose one of our eCards templates
                and customize it.</TextSplash></h4>
            </div>
          </div>
          <div className="col m6">
            {/* <CardContainer className="card"> */}
            {/* <div className="card-image"> */}
            <img src={first} alt="" />
            <span className="card-title">&nbsp;</span>
            {/* </div> */}
            {/* </CardContainer> */}
          </div>
        </div>
        <div className="row row-2 purple-text text-darken-1">
          <div className="col m6">
            {/* <CardContainer className="card"> */}
            {/* <div className="card-image"> */}
            <img src={second} alt="" />
            <span className="card-title">&nbsp;</span>
            {/* </div> */}
            {/* </CardContainer> */}
          </div>
          <div className="col m6">
            <div className="img-content">
              <h2 style={{ display: "flex" }}><img src={two} alt="" /><b><TextSplash>Choose </TextSplash><br /><TextSplash>it</TextSplash></b></h2>
              <h4><TextSplash>Choose up to three gift choices from Amazon, entering the gift links (URLs) into the form.</TextSplash></h4>
            </div>
          </div>
        </div>
        <div className="row row-3 purple-text text-darken-1">
          <div className="col m6">
            <div className="img-content">
              <h2 style={{ display: "flex" }}><img src={three} alt="" /><b><TextSplash>Givit!</TextSplash><br /><TextSplash></TextSplash></b></h2>
              <h4><TextSplash>You can send your Givit link by email or any other form of communication (Whatsapp, Messenger, SMS, etc).</TextSplash></h4>
            </div>
          </div>
          <div className="col m6">
            {/* <CardContainer className="card"> */}
            {/* <div className="card-image"> */}
            <img src={third} alt="" />
            <span className="card-title">&nbsp;</span>
            {/* </div> */}
            {/* </CardContainer> */}
          </div>
        </div>

      </div>
    </section>
  </HowWorksSectionContainer>

</>);

export default HowWorksComponent;
