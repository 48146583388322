import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectCurrentUser,
  selectPendingRecipients,
  selectAmazonAccountVerified,
  selectProcessing,
} from "../../redux/user/user.selectors";
import {
  deleteRecipientByCreatedIdStart,
  getPendingRecipientsStart, getRecipientsByTemplatenameStart,
  resetCodeStart,
  setDestinationPath,
  updateApprovedRecipientStart,
} from "../../redux/user/user.actions";
import { Button } from "react-materialize";
import OnePendingRecipient from "../../components/a-pending-recipient/a-pending-recipient.component";
import { Link, withRouter } from "react-router-dom";
import { DownloadWrapper, PendingSectionContainer, CardPanelContainer } from "./pending.styles";
import Overlay from "../../components/overlay/overlay.component";
import { compose } from 'redux';
import useSocNsRm from "../../hooks/useSocNsRm";
import { CSVLink } from "react-csv";
import { localInfo } from "../../utils/constants";
import { Table } from "react-materialize";

const headers = [
  { label: "Email", key: "recipientemail" },
  { label: "Recipient", key: "recipient_name" },
  { label: "Ecard", key: "template_name" },
  { label: "Accepted", key: "date_created" },
  { label: "Selected", key: "item_name" },
  { label: "Status", key: "status" }
];

const PendingRecipients = (props) => {
  const {
    currentUser,
    pendingRecipients,
    deleteRecipientByCreatedId,
    inProgress,
    updatePendingRecipientRowApprovedStatus,
    getRecipientsByTemplatename,
    resetCode,
    location: { data: { template_name } }
  } = props;
  const [theRecipients, setTheRecipients] = useState(null);
  const [csvFilename, setCsvFilename] = useState(`${(new Date()).toString().substring(0, 21).replaceAll(":", " ").replaceAll(" ", "_")}_recipients.csv`);
  const [, updateState] = React.useState();
  const csvLinkEl = React.createRef();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    const {
      setDestinationPath,
      history,
      match: { url },
    } = props;

    async function fetchData() {
      try {
        if (!(currentUser && currentUser?.id)) {
          throw new Error("No user found.");
        }
        await getRecipientsByTemplatename({currentUser, template_name});
        await setTheRecipients(pendingRecipients);
        await resetCode(currentUser);
      } catch (error) {
        setDestinationPath(url);
        history.push(`/signin`);
      }
    }

    if (currentUser) {
      // verifyAmazonAccountAvailability(currentUser);
      // joinTheRoom(currentRoom, socket);

      // const executionIdentifier = window.localStorage.getItem("eid") ?? Math.random() * 9099;
      // window.localStorage.setItem("eid", executionIdentifier);
      // sendData("prep-driver", { currentUser, executionIdentifier }, socket);

      fetchData();
    } else {
      setDestinationPath(url);
      history.push(`/signin`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, pendingRecipients?.length, getRecipientsByTemplatename, resetCode]);

  const filterByApprovedRecipients = _ => {
    return theRecipients?.filter((recipient) => recipient.is_approved && recipient.giver_confirmed);
  };


  const onChecked = async ({ id, checked, recipientRow }) => {
    localInfo("checked: ", checked);
    let pendingRecipientsClone = pendingRecipients;
    pendingRecipientsClone[parseInt(id)].is_approved = checked;

    await updatePendingRecipientRowApprovedStatus({
      currentUser,
      recipientRow,
      checked: pendingRecipientsClone[parseInt(id)].is_approved,
      status: checked ? "Approved" : "Denied",
      // giver_confirmed: true,
      // giver_confirmed to identify it has been touched or not, if null giver_confirmed
      // usually means that it was Pending Approval. This is important for the stats update at backend.
      template_name,
    });
    await setTheRecipients((prevRecipients) => {
      let items = [...prevRecipients];
      items[parseInt(id)].is_approved = checked;
      items[parseInt(id)].status = checked ? "Approved" : "Denied";
      // items[parseInt(id)].giver_confirmed = true;
      return items;
    });
    await forceUpdate();
  };

  const onDelete = async (id) => {
    await deleteRecipientByCreatedId({
      theRecipient: theRecipients[parseInt(id)],
      currentUser,
    });
    localInfo("theRecipients: ", theRecipients);
    await setTheRecipients(prevState => {
      return prevState.splice(parseInt(id),1);
    });

    await forceUpdate();
    return;
  };

  const onSubmitExecution = async (event) => {
    event.preventDefault();
  };

  const downloadReport = async _ => {
    setCsvFilename(_ => `${(new Date()).toString().substring(0, 21).replaceAll(":", " ").replaceAll(" ", "_")}_recipients.csv`);
    csvLinkEl.current?.link.click();
  };

  return (
    <React.Fragment>
      <section className="section section-pending-list">
        <PendingSectionContainer>
          <div className="row">
            <div className="col s12 m12 l12">
              <CardPanelContainer className="card-panel hoverable grey lighten-4 grey-text text-darken-3 z-depth-0">
                <h4>Recipients List</h4>
                {pendingRecipients && (pendingRecipients.length > 0) && <span>
                  These are the recipients who have confirmed their gift selection.
                </span>}
                {pendingRecipients && (pendingRecipients.length === 0) && <span>
                  Nothing yet!
                </span>}

                <br />
                <form onSubmit={onSubmitExecution}>

                  {pendingRecipients && (pendingRecipients.length > 0) &&
                    inProgress === false && (
                      <Table>
                        <thead>
                          <tr>
                            <th style={{ width: "5rem" }}>Email</th>
                            <th>Recipient</th>
                            <th>Ecard Name</th>
                            <th>Accepted</th>
                            <th style={{ width: "10rem" }}>Selected</th>
                            <th>&nbsp;</th>
                            <th>Approve</th>
                            <th>Delete</th>
                          </tr>
                        </thead>

                        <tbody>
                          {pendingRecipients.map((val, index) => (
                            <OnePendingRecipient
                              onDelete={onDelete}
                              recipientRow={val}
                              key={index}
                              id={index}
                              onChange={onChecked}
                            />
                          ))
                          }
                        </tbody>
                      </Table>
                    )}
                  <br />
                  {pendingRecipients && (pendingRecipients.length > 0) && <div className="container">
                    <div className="row">
                      <DownloadWrapper className="col s2 m2" onClick={downloadReport}>
                        <p><i class="material-icons">file_download</i></p>
                        <p>Download CSV</p>
                        <CSVLink
                          headers={headers}
                          filename={csvFilename}
                          data={pendingRecipients}
                          ref={csvLinkEl}
                        />
                      </DownloadWrapper>
                      <div className="col s2 m2"></div>
                      <div className="col s5 m5">
                        <Link
                          to={{
                            pathname:
                              pendingRecipients?.filter((recipient) => recipient.is_approved)?.length === 0 ? "/user/dashboard" : "/ama-cred",
                            state: {
                              recipients: pendingRecipients?.filter((recipient) => recipient.is_approved),
                            },
                          }}
                        >
                          <Button
                            node="button"
                            type="submit"
                            className="btn btn-large orange waves-effect waves-light"
                            waves="light"
                            disabled={pendingRecipients?.filter((recipient) => recipient.is_approved)?.length === 0}
                          >
                            <i className="material-icons left">done</i>Proceed
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>}
                </form>
              </CardPanelContainer>
            </div>
          </div>
        </PendingSectionContainer>
      </section>
      {inProgress && <Overlay />}
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  pendingRecipients: selectPendingRecipients,
  amazonAcctPresence: selectAmazonAccountVerified,
  inProgress: selectProcessing,
});

const mapDispatchToProps = (dispatch) => ({
  setDestinationPath: (path) => dispatch(setDestinationPath(path)),
  getRecipientsByTemplatename: ({currentUser, template_name}) => dispatch(getRecipientsByTemplatenameStart({currentUser, template_name})),
  resetCode: (user) => dispatch(resetCodeStart(user)),
  deleteRecipientByCreatedId: (payload) =>
    dispatch(deleteRecipientByCreatedIdStart(payload)),
  updatePendingRecipientRowApprovedStatus: (payload) =>
    dispatch(updateApprovedRecipientStart(payload)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PendingRecipients);
