import React, {useEffect, useRef} from "react";
import Header from "../../components/header/header.component";
import { CopyInstructionContainer, OptionHeaderContainer } from "./created-ecard.styles";
import videoimg from "../../assets/video.png";
import gifimg from "../../assets/gif.png";
import {
  Button,
  TextInput,
  Col,
  Row,
} from "react-materialize";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { toast } from "react-toastify";
import "./created-ecard.styles.scss";
import { selectAllLandingImages, selectAllNames, selectedGiftSet } from "../../redux/gift-sets/gift-sets.selectors";
import { Editor } from "@tinymce/tinymce-react";
import { selectAllCards, selectMessage } from '../../redux/cards/cards.selectors';
import { useState } from "react";
import { localError, localInfo } from "../../utils/constants";
import { withRouter } from "react-router-dom";
import { updateTemplateDataStart } from "../../redux/user/user.actions";
import FooterFinal from "../footer/footer.component";

const CreatedEcard = ({
    themessage,
    updateTemplateData,
    currentUser,
    all_names,
    all_images,
    getAllCards,
    giftSet,
    location: { state: { templateName } } }) => {
  const animatedUrlTextBoxRef = useRef(null);
  const editorRef = useRef(null);
  let found = [];
  const url_noimg =
    "https://click2kemah.com/directory/wp-content/uploads/2019/04/No-image-available.jpg";

  const [messageHtml, setMessageHtml] = useState("");
  const [message, setMessage] = useState({
    lineOne: {
      phrase: themessage,
      style: {
        color: "#ff9900",
      },
      textStyle: "color: #ff9900",
    },
    lineTwo: {
      phrase: "",
      style: {
        color: "#ff9900",
      },
      textStyle: "color: #ff9900",
    },
    lineThree: {
      phrase: "",
      style: {
        color: "#ff9900",
      },
      textStyle: "color: #ff9900",
    },
  });

    useEffect(() => {
        let k = setInterval(() => {
            if(window?.tinymce?.activeEditor?.dom && editorRef?.current && currentUser?.email) {
                saveContentAutomatic();
                clearInterval(k);
            }
        }, 1000);
    }, [themessage, updateTemplateData, currentUser]);
  const copyAnimatedUrl = () => {
    saveContentAutomatic();
    animatedUrlTextBoxRef.current.select();
    animatedUrlTextBoxRef.current.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(animatedUrlTextBoxRef.current.value);
    toast.success("Copied successfully to clipboard.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      pauseOnFocusLoss: false,
    });
  };

  const preSelectedTemplate = () => {
    found = [];
    for (var i = 0; i < getAllCards.length; ++i) {
      found = getAllCards[i].pathSrcs.filter((item) => item.selected);
      if (found.length > 0) {
        break;
      }
    }
    if (found.length > 0) {
      return `
      <div style="text-align: center;">
<div style="background-image: url('${found[0].src
      }');background-repeat: no-repeat;background-size: 100% 100%; height: 25em"></div>
        <div style="background-image: url('https://www.getgivit.com/templatize/envelope_bgpaste.png');background-repeat: no-repeat;background-size: 100% 100%;">
        <br />
<p style="text-align: center; margin-top: 2rem;margin-left: 1rem;margin-right: 1rem; color: black;" class="messaging"><span id="lineOne" class="lineOne" style="${message.lineOne.style.textFormat
        }">${message.lineOne.phrase
        }</span><br /><span id="lineTwo" class="lineTwo" style="${message.lineTwo.style.textFormat
        }">${message.lineTwo.phrase
        }</span><br /><span id="lineThree" class="lineThree" style="${message.lineThree.style.textFormat
        }">${message.lineThree.phrase}</span></p>

    `;
    } else return ``;
  };

  const giftTable = _ => {
      const { email } = currentUser;
      return `
<h5 style="text-align: center;"><span style="color: #ff9900;">Choose from one of these gifts:</span></h5>
<table style="height: 52px; width: 93%; border-style: none; margin-left: auto; margin-right: auto;">

<tbody>
<tr>
${all_images && all_images[0] && `
<td style="width: 3.3333%; text-align: center;"><a href=${giftSet && giftSet[0]
          ? `https://www.getgivit.com/gift/${window.btoa(
              encodeURIComponent(templateName)
          )}/${window.btoa(encodeURIComponent("https://onetreeplanted.org/collections/africa/products/africa"))}/${window.btoa(
              encodeURIComponent(email)
          )}/${window.btoa(encodeURIComponent("Plant A Tree in Ethiopia"))}`
          : "#"
      }
       target="_blank"><img src="https://getgivit.com/templatize/plantree.png" alt="Plant a tree picture" width="150" height="150" /></a></td>

`}
    <td>
        <tr>

      ${all_names && all_names[0] && `
      <td style="width: 100%; text-align: center;color=black;">If you rather not receive a gift, you can choose instead to plant a tree. We will make a donation on your behalf to the rainforest restoration project in OneTreePlanted. <br /><a href="https://onetreeplanted.org/collections/africa/products/africa" target="_blank">See details.</a><br /></td>
      `}


        </tr>

        <tr>
        <td>
        <div style="display: block">
        <a id="gift1_ama" style="background: #15d798; text-align: center; text-decoration: none; border-radius: 11px; padding: 20px 45px; margin-top: 10px; color: #ffffff;font: bold 16px / 1 sans-serif" href=${giftSet && giftSet[0]
          ? `https://www.getgivit.com/gift/${window.btoa(
              encodeURIComponent(templateName)
          )}/${window.btoa(encodeURIComponent("https://onetreeplanted.org/collections/africa/products/africa"))}/${window.btoa(
              encodeURIComponent(email)
          )}/${window.btoa(encodeURIComponent("Plant A Tree in Ethiopia"))}`
          : "#"
      } type="button">Select</a>
</div>
        </td>
        </tr>

    </td>
</tr>





<tr>
${all_images && all_images[0] && `
<td style="width: 3.3333%; text-align: center;"><a href=${giftSet && giftSet[0]
          ? `https://www.getgivit.com/gift/${window.btoa(
              encodeURIComponent(templateName)
          )}/${window.btoa(encodeURIComponent(giftSet[0]))}/${window.btoa(
              encodeURIComponent(email)
          )}/${window.btoa(encodeURIComponent(all_names[0]))}`
          : "#"
      } target="_blank"><img src=${all_images && all_images[0] ? all_images[0] : url_noimg
      } alt=${all_names && all_names[0] ? all_names[0] : "Unidentified Object"
      } width="150" height="150" /></a></td>

`}
    <td>
        <tr>

      ${all_names && all_names[0] && `
      <td style="width: 100%; text-align: center; color=black">${all_names && all_names[0] ? all_names[0].replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "").trim().substring(0,96)+"..." : "Unidentified Object"
      }<br /><a href="${giftSet[0]
      }" target="_blank">See details.</a><br /></td>
      `}


        </tr>

        <tr>
        <td>
        <div style="display: block">
        <a id="gift1_ama" style="background: #15d798; text-align: center; text-decoration: none; border-radius: 11px; padding: 20px 45px; margin-top: 10px; color: #ffffff;font: bold 16px / 1 sans-serif" href=${giftSet && giftSet[0]
          ? `https://www.getgivit.com/gift/${window.btoa(
              encodeURIComponent(templateName)
          )}/${window.btoa(encodeURIComponent(giftSet[0]))}/${window.btoa(
              encodeURIComponent(email)
          )}/${window.btoa(encodeURIComponent(all_names[0]))}`
          : "#"
      } type="button">Select</a>
</div>
        </td>
        </tr>

    </td>
</tr>





<tr>
${all_images && all_images[1] && `
<td style="width: 3.3333%; text-align: center;"><a href=${giftSet && giftSet[1]
          ? `https://www.getgivit.com/gift/${window.btoa(
              encodeURIComponent(templateName)
          )}/${window.btoa(encodeURIComponent(giftSet[1]))}/${window.btoa(
              encodeURIComponent(email)
          )}/${window.btoa(encodeURIComponent(all_names[1]))}`
          : "#"
      } target="_blank"><img src=${all_images && all_images[1] ? all_images[1] : url_noimg
      } alt=${all_names && all_names[1] ? all_names[1] : "Unidentified Object"
      } width="150" height="150" /></a></td>

`}
    <td>
        <tr>

      ${all_names && all_names[1] && `
      <td style="width: 100%; text-align: center;color: black;">${all_names && all_names[1] ? all_names[1].replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "").trim().substring(0,96)+"..." : "Unidentified Object"
      }<br /><a href="${giftSet[1]
      }" target="_blank">See details.</a><br /></td>
      `}


        </tr>

        <tr>
        <td>
        <div style="display: block">
        <a id="gift1_ama" style="background: #15d798; text-align: center; text-decoration: none; border-radius: 11px; padding: 20px 45px; margin-top: 10px; color: #ffffff;font: bold 16px / 1 sans-serif" href=${giftSet && giftSet[1]
          ? `https://www.getgivit.com/gift/${window.btoa(
              encodeURIComponent(templateName)
          )}/${window.btoa(encodeURIComponent(giftSet[1]))}/${window.btoa(
              encodeURIComponent(email)
          )}/${window.btoa(encodeURIComponent(all_names[1]))}`
          : "#"
      } type="button">Select</a>
</div>
        </td>
        </tr>

    </td>
</tr>





<tr>
${all_images && all_images[2] && `
<td style="width: 3.3333%; text-align: center;"><a href=${giftSet && giftSet[2]
          ? `https://www.getgivit.com/gift/${window.btoa(
              encodeURIComponent(templateName)
          )}/${window.btoa(encodeURIComponent(giftSet[2]))}/${window.btoa(
              encodeURIComponent(email)
          )}/${window.btoa(encodeURIComponent(all_names[2]))}`
          : "#"
      } target="_blank"><img src=${all_images && all_images[2] ? all_images[2] : url_noimg
      } alt=${all_names && all_names[2] ? all_names[2] : "Unidentified Object"
      } width="150" height="150" /></a></td>

`}
    <td>
        <tr>

      ${all_names && all_names[2] && `
      <td style="width: 100%; text-align: center;">${all_names && all_names[2] ? all_names[2].replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "").trim().substring(0,96)+"..." : "Unidentified Object"
      }<br /><a href="${giftSet[2]
      }" target="_blank">See details.</a><br /></td>
      `}


        </tr>

        <tr>
        <td>
        <div style="display: block">
        <a id="gift1_ama" style="background: #15d798; text-align: center; text-decoration: none; border-radius: 11px; padding: 20px 45px; margin-top: 10px; color: #ffffff;font: bold 16px / 1 sans-serif" href=${giftSet && giftSet[2]
          ? `https://www.getgivit.com/gift/${window.btoa(
              encodeURIComponent(templateName)
          )}/${window.btoa(encodeURIComponent(giftSet[2]))}/${window.btoa(
              encodeURIComponent(email)
          )}/${window.btoa(encodeURIComponent(all_names[2]))}`
          : "#"
      } type="button">Select</a>
</div>
        </td>
        </tr>

    </td>
</tr>


</tbody>

</table><p style="text-align: center;">&nbsp;</p>
</div>
</div>

      `;
  };

  const giftTable2 = () => {

    const { email } = currentUser;
    return `

<h5 style="text-align: center;"><span style="color: #ff9900;">Choose from one of these gifts:</span></h5>
<table style="height: 52px; width: 62.6175%; border-style: none; margin-left: auto; margin-right: auto;" border="0">

<tbody>
<tr>
${all_images && all_images[0] && `
<td style="width: 3.3333%; text-align: center;"><a href=${giftSet && giftSet[0]
        ? `https://www.getgivit.com/gift/${window.btoa(
          encodeURIComponent(templateName)
        )}/${window.btoa(encodeURIComponent(giftSet[0]))}/${window.btoa(
          encodeURIComponent(email)
        )}/${window.btoa(encodeURIComponent(all_names[0]))}`
        : "#"
      } target="_blank"><img src=${all_images && all_images[0] ? all_images[0] : url_noimg
      } alt=${all_names && all_names[0] ? all_names[0] : "Unidentified Object"
      } width="150" height="150" /></a></td>

`}
      ${all_images && all_images[1] && `
      <td style="width: 3.3333%; text-align: center;"><a href=${giftSet && giftSet[1]
        ? `https://www.getgivit.com/gift/${window.btoa(
          encodeURIComponent(templateName)
        )}/${window.btoa(encodeURIComponent(giftSet[1]))}/${window.btoa(
          encodeURIComponent(email)
        )}/${window.btoa(encodeURIComponent(all_names[1]))}`
        : "#"
      } target="_blank"><img src=${all_images && all_images[1] ? all_images[1] : url_noimg
      } alt=${all_names && all_names[1] ? all_names[1] : "Unidentified Object"
      } width="150" height="150" /></a></td>
      `}
      ${all_images && all_images[2] && `
      <td style="width: 3.3333%; text-align: center;"><a href=${giftSet && giftSet[2]
        ? `https://www.getgivit.com/gift/${window.btoa(
          encodeURIComponent(templateName)
        )}/${window.btoa(encodeURIComponent(giftSet[2]))}/${window.btoa(
          encodeURIComponent(email)
        )}/${window.btoa(encodeURIComponent(all_names[2]))}`
        : "#"
      } target="_blank"><img src=${all_images && all_images[2] ? all_images[2] : url_noimg
      } alt=${all_names && all_names[2] ? all_names[2] : "Unidentified Object"
      } width="150" height="150" /></a></td>
      `}
</tr>
<tr>
${all_names && all_names[0] && `
<td style="width: 3.3333%; text-align: center;"><a href="${giftSet[0]
      }" target="_blank">${all_names && all_names[0] ? all_names[0] : "Unidentified Object"
      }</a></td>
`}
      ${all_names && all_names[1] && `
      <td style="width: 3.3333%; text-align: center;"><a href="${giftSet[1]
      }" target="_blank">${all_names && all_names[1] ? all_names[1] : "Unidentified Object"
      }</a></td>
      `}
      ${all_names && all_names[2] && `
      <td style="width: 3.3333%; text-align: center;"><a href="${giftSet[2]
      }" target="_blank">${all_names && all_names[2] ? all_names[2] : "Unidentified Object"
      }</a></td>
      `}
</tr>
<tr>
${all_images && all_images[0] && `
<td style="width: 3.3333%; text-align: center;"><a id="gift0_ama" style="background: #15d798; border-radius: 11px; padding: 20px 45px; color: #ffffff; display: inline-block; font: bold 16px / 1 'Open Sans', sans-serif; text-align: center; text-decoration: none;" href=${giftSet && giftSet[0]
        ? `https://www.getgivit.com/gift/${window.btoa(
          encodeURIComponent(templateName)
        )}/${window.btoa(encodeURIComponent(giftSet[0]))}/${window.btoa(
          encodeURIComponent(email)
        )}/${window.btoa(encodeURIComponent(all_names[0]))}`
        : "#"
      } type="button">Select</a></td>
`}
      ${all_images && all_images[1] && `
      <td style="width: 3.3333%; text-align: center;"><a id="gift1_ama" style="background: #15d798; border-radius: 11px; padding: 20px 45px; color: #ffffff; display: inline-block; font: bold 16px / 1 'Open Sans', sans-serif; text-align: center; text-decoration: none;" href=${giftSet && giftSet[1]
        ? `https://www.getgivit.com/gift/${window.btoa(
          encodeURIComponent(templateName)
        )}/${window.btoa(encodeURIComponent(giftSet[1]))}/${window.btoa(
          encodeURIComponent(email)
        )}/${window.btoa(encodeURIComponent(all_names[1]))}`
        : "#"
      } type="button">Select</a></td>
      `}
      ${all_images && all_images[2] && `
      <td style="width: 3.3333%; text-align: center;"><a id="gift2_ama" style="background: #15d798; border-radius: 11px; padding: 20px 45px; color: #ffffff; display: inline-block; font: bold 16px / 1 'Open Sans', sans-serif; text-align: center; text-decoration: none;" href=${giftSet && giftSet[2]
        ? `https://www.getgivit.com/gift/${window.btoa(
          encodeURIComponent(templateName)
        )}/${window.btoa(encodeURIComponent(giftSet[2]))}/${window.btoa(
          encodeURIComponent(email)
        )}/${window.btoa(encodeURIComponent(all_names[2]))}`
        : "#"
      } type="button">Select</a></td>

      `}
</tr>
</tbody>

</table><p style="text-align: center;">&nbsp;</p>
</div>
</div>
    `;
  };


  const initTemplate = () => {
    return `${preSelectedTemplate()}${giftTable()}`;
  };

  const handleEditorChange = (e) => { };
  const onEditorContentChange = (content) => {
    let container = document.createElement("div");
    container.innerHTML = content;
    let messageElement = container?.getElementsByClassName("messaging")[0];
    setMessageHtml((_) => messageElement.innerHTML);
  };

  const getContent = () => {
    if (editorRef?.current) {
      const template_html = editorRef.current.getContent();
      const { email, idToken } = currentUser;
      return { email, templateName, template_html, idToken };
    }
  };


  const saveContentAutomatic = _ => {
    if (
      window.tinymce.activeEditor.dom.get("lineOne") &&
      all_names &&
      all_images
    ) {
      localInfo("Start Save Content Automatic.");
      localInfo("Setting message content text and styles.");
      setMessage((_) => ({
        lineOne: {
          phrase: window.tinymce.activeEditor.dom.get("lineOne").innerText,
          style: {
            textFormat: window.tinymce.activeEditor.dom.getAttrib(
              "lineOne",
              "style",
              ""
            ),
          },
        },
        lineTwo: {
          phrase: window.tinymce.activeEditor.dom.get("lineTwo").innerText,
          style: {
            textFormat: window.tinymce.activeEditor.dom.getAttrib(
              "lineTwo",
              "style",
              ""
            ),
          },
        },
        lineThree: {
          phrase: window.tinymce.activeEditor.dom.get("lineThree").innerText,
          style: {
            textFormat: window.tinymce.activeEditor.dom.getAttrib(
              "lineThree",
              "style",
              ""
            ),
          },
        },
      }));

      localInfo("templateName is " + templateName);
        localInfo("giftSet: ", giftSet);
      const { email } = getContent();

      const templateData = {
        consumed: false,
        selectedEcardUrl: found[0].src,
        message,
        messageHtml,
        giftItems: all_names.map((itemname, index) => ({
            giftItemDesc: itemname,
            giftItemUrl: giftSet[index],
            giftItemSelect: `https://www.getgivit.com/gift/${window.btoa(
                encodeURIComponent(templateName)
            )}/${window.btoa(encodeURIComponent(giftSet[index]))}/${window.btoa(
                encodeURIComponent(email)
            )}/${window.btoa(encodeURIComponent(itemname))}`,
            giftItemImage: all_images[index],
            claimed: false,
        }))
        /*
            [
          {
            giftItemDesc: all_names[0],
            giftItemUrl: giftSet[0],
            giftItemSelect: `https://www.getgivit.com/gift/${window.btoa(
              encodeURIComponent(templateName)
            )}/${window.btoa(encodeURIComponent(giftSet[0]))}/${window.btoa(
              encodeURIComponent(email)
            )}/${window.btoa(encodeURIComponent(all_names[0]))}`,
            giftItemImage: all_images[0],
            claimed: false,
          },
          {
            giftItemDesc: all_names[1],
            giftItemUrl: giftSet[1],
            giftItemSelect: `https://www.getgivit.com/gift/${window.btoa(
              encodeURIComponent(templateName)
            )}/${window.btoa(encodeURIComponent(giftSet[1]))}/${window.btoa(
              encodeURIComponent(email)
            )}/${window.btoa(encodeURIComponent(all_names[1]))}`,
            giftItemImage: all_images[1],
            claimed: false,
          },
          {
            giftItemDesc: all_names[2],
            giftItemUrl: giftSet[2],
            giftItemSelect: `https://www.getgivit.com/gift/${window.btoa(
              encodeURIComponent(templateName)
            )}/${window.btoa(encodeURIComponent(giftSet[2]))}/${window.btoa(
              encodeURIComponent(email)
            )}/${window.btoa(encodeURIComponent(all_names[2]))}`,
            giftItemImage: all_images[2],
            claimed: false,
          },
            {
                giftItemDesc: "If you rather not receive a gift, you can choose instead to plant a gift-tree. We will make a donation on your behalf to the rainforest restoration project in Ethiopia.",
                giftItemUrl: "https://www.getgivit.com/templatize/plantree.png",
                giftItemSelect: `https://www.getgivit.com/gift/tree`,
                giftItemImage: "https://www.getgivit.com/templatize/plantree.png",
                claimed: false,
            },

        ],

         */
      };

      const finalPayload = {
        email,
        template_name: templateName,
        idToken: currentUser.idToken,
        template_data: JSON.stringify(templateData),
      };
      localInfo("updateTemplateData: ", finalPayload);
      updateTemplateData(finalPayload);

    } else {
      localError("NOT READY to save content");
    }
  };

  const copyContentToClipboard = () => {
    saveContentAutomatic();
      const editor = window.tinymce.activeEditor;
      if (editor) {
          editor.selection.select(editor.getBody());
          document.execCommand('copy');
      }
    toast.success("Copied to Clipboard", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      pauseOnFocusLoss: false,
    });
  };


  return <React.Fragment>
    <Header titlePage={"Your ecard was successfully created!"} />
    <section className="created-ecard-section">
      <div className="container black-text">
        <div className="row">
          <div className="col s12 m6 black-text text-darken-4">
            <OptionHeaderContainer>
              <h2>
                <b>Option #1</b>
              </h2>
            </OptionHeaderContainer>
            <CopyInstructionContainer>
              <h4 style={{
                margin: 'auto 0rem',
              }}><b>Copy and share this</b></h4>
            </CopyInstructionContainer>

            <Row>
              <Col
                style={{
                  float: "none",
                  width: "280px",
                  margin: "0 auto",
                  display: "flex",
                  borderRadius: '10rem',
                  justifyContent: 'center',
                }}
                className=""
                xs={12}

              >
                <TextInput
                  id="animated-version"
                  ref={animatedUrlTextBoxRef}
                  defaultValue={`https://getgivit.com/ecard/${window.btoa(
                    encodeURIComponent(currentUser.email)
                  )}/${window.btoa(encodeURIComponent(templateName))}`}
                  style={{
                    border: 'solid',
                    borderColor: 'purple',
                    padding: '1rem',
                    borderRadius: '3rem',
                    textAlign: 'center',
                    width: '100%',
                    justifyContent: 'center',
                    marginLeft: '-1rem',
                  }}
                />
              </Col>

            </Row>

            <Row>
              <Col
                style={{
                  float: "none",
                  width: "280px",
                }}
                xs={12}
              >
              </Col>
              <Col
                style={{
                  float: "none",
                  width: "280px",
                  margin: "0 auto",
                  display: "flex",
                  borderRadius: '10rem',
                  justifyContent: 'center',
                }}
                className=""
                xs={12}

              >
                <Button
                  node="a"
                  small
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    zIndex: "999",
                    backgroundColor: 'orange',
                    fontWeight: 'bolder',
                    width: '40%',
                    fontSize: '19px'
                  }}
                  waves="light"
                  onClick={copyAnimatedUrl}
                >
                  <b>Copy</b>
                </Button>
              </Col>

            </Row>

            <Row>
              <Col
                style={{
                  float: "none",
                  width: "280px",
                  margin: "0 auto",
                  display: "flex",
                  borderRadius: '10rem',
                  justifyContent: 'center',
                }}
                className=""
                xs={12}

              >
                <img src={gifimg} alt="" style={{ width: 'fit-content', height: 'fit-content', margin: 'auto 7rem' }} />
              </Col>

            </Row>


          </div>
          <div className="col s12 m6 black-text text-darken-4">
            <OptionHeaderContainer>
              <h2>
                <b>Option #2</b>
              </h2>
            </OptionHeaderContainer>
            <CopyInstructionContainer>
              <h4><b>Copy rich text format to paste directly to your email</b></h4>
            </CopyInstructionContainer>
            <Row>
              <Col
                style={{
                  float: "none",
                  width: "280px",
                }}
                xs={12}
              >
              </Col>
              <Col
                style={{
                  float: "none",
                  width: "280px",
                  margin: "0 auto",
                  display: "flex",
                  borderRadius: '10rem',
                  justifyContent: 'center',
                }}
                className=""
                xs={12}

              >
                <Button
                  node="a"
                  small
                  style={{
                    textAlign: "center",
                    marginTop: "25px",
                    zIndex: "999",
                    backgroundColor: 'orange',
                    fontWeight: 'bolder',
                    width: '40%',
                    fontSize: '19px'
                  }}
                  waves="light"
                  onClick={copyContentToClipboard}
                >
                  <b>Copy</b>
                </Button>
              </Col>

            </Row>

            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              apiKey={process.env.REACT_APP_EDITOR_API_KEY}
              initialValue={initTemplate()}
              onEditorChange={onEditorContentChange}
              init={{
                custom_ui_selector: ".my-custom-button",
                height: 500,
                width: 500,
                branding: false,
                menubar: false,
                toolbar: false,
                disabled: true,
                inline: true,
                plugins: [
                  "code advlist autolink lists link image",
                  "charmap print anchor",
                  "searchreplace visualblocks",
                  "media table",
                  "autolink lists media table code",
                ],
                automatic_uploads: true,
              }}
              onChange={handleEditorChange}
            />


          </div>
        </div>

        <div className="row">
          <div className="col s12 m12 purple-text text-darken-4">
            <OptionHeaderContainer>
              <h2>

                <b>How to send your ecard?</b>
              </h2>
              <img src={videoimg} alt="" style={{ width: 'fit-content', height: 'fit-content' }} />
            </OptionHeaderContainer>
          </div>
        </div>
      </div>
    </section>
    <FooterFinal />
  </React.Fragment>
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  all_names: selectAllNames,
  all_images: selectAllLandingImages,
  getAllCards: selectAllCards,
  giftSet: selectedGiftSet,
  themessage: selectMessage,
});

const mapDispatchToProps = dispatch => ({
  updateTemplateData: (payload) => dispatch(updateTemplateDataStart(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatedEcard));
