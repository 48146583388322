import {
  PendingPreviewContainer,
  PreviewContainer,
  CardPanelContainer,
  ParaDescriptor,
} from "./pending-preview.styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, Divider, Button, Icon, Modal, TextInput } from "react-materialize";
import { useState } from "react";

const PendingPreview = ({ history, match: { url, path } }) => {

  const [currentOrg, setCurrentOrg] = useState("khairul@tunabear.com");
  const selectOrg = e => {
    setCurrentOrg(e.target.innerHTML);
    // update the store
  };
  return (<PendingPreviewContainer>
    <PreviewContainer>
      <div>
        <div className="row">
          <div className="col s12 m6 l4 black-text dark">
            <h6><b>Organization:</b></h6>
          </div>
          <div className="col s12 m6 l4">
            <Dropdown
              id="organization_dropdown"
              options={{
                alignment: 'left',
                autoTrigger: true,
                closeOnClick: true,
                constrainWidth: true,
                container: null,
                coverTrigger: true,
                hover: false,
                inDuration: 150,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 250
              }}
              trigger={<Button id="current_org" style={{ width: "20rem" }} node="button" className="purple">{currentOrg}</Button>}
            >
              <div style={{ padding: "10px", textAlign: "center" }}>
                Switch organization context
              </div>
              <Divider />
              <a onClick={selectOrg} id="defaultOrg">
                _____
              </a>
              <a onClick={selectOrg} id="tunabear">
                _____
              </a>

              <Link to="/s/settings/org">
                <Icon>
                  view_module
                </Icon>
                Manage organizations
              </Link>
              <a className="modal-trigger" href="#new_org">
                <Icon>
                  add
                </Icon>
                Create organization
              </a>
            </Dropdown>
            <Modal
              actions={[
                <Button
                  onClick={() => { }}
                  modal="close"
                  node="button"
                  waves="light"
                  className="blue lighten-2 white-text"
                >
                  Confirm
                </Button>,
                <span style={{ padding: "10px" }}>&nbsp;</span>,
                <Button
                  modal="close"
                  node="button"
                  waves="light"
                  className="white black-text"
                >
                  Cancel
                </Button>,
              ]}
              bottomSheet={false}
              fixedFooter={false}
              header="Create new organization"
              id="new_org"
              open={false}
              options={{
                dismissible: true,
                endingTop: "10%",
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                opacity: 0.5,
                outDuration: 250,
                preventScrolling: true,
                startingTop: "4%",
              }}
              className="black-text"
            >
              <TextInput
                id="orgName"
                placeholder="Organization Name"
                onChange={() => { }}
                icon="add"
                name="orgName"
                type="text"
                required
                value={""}
              />

              <TextInput
                id="orgEmail"
                placeholder="Organization Email"
                onChange={() => { }}
                icon="email"
                name="email"
                type="email"
                required
                value={""}
              />
            </Modal>
          </div>
        </div>
      </div>
    </PreviewContainer>
    <PreviewContainer>
      <div className="">
        <div className="row">
          <div className="col s12  m6 l4">
            <Link className="option" to={`${url}/recipients`}>
              <CardPanelContainer className="card-panel hoverable grey lighten-4 grey-text text-darken-3 z-depth-5">
                <h5 className="grey-text text-darken-4">Recipients</h5>
                <ParaDescriptor>Recipients who have made gift selection.</ParaDescriptor>
              </CardPanelContainer>
            </Link>
          </div>
          <div className="col s12 m6 l4">
            <Link className="option" to={`${url}/ecards`}>
              <CardPanelContainer className="card-panel hoverable grey lighten-4 grey-text text-darken-3 z-depth-5">
                <h5 className="grey-text text-darken-4">Ecards</h5>
                <ParaDescriptor>Ecards that you sent out</ParaDescriptor>
              </CardPanelContainer>
            </Link>
          </div>
          <div className="col s12 m6 l4">
            <Link className="option" to={`${url}/orders`}>
              <CardPanelContainer className="card-panel hoverable grey lighten-4 grey-text text-darken-3 z-depth-5">
                <h5 className="grey-text text-darken-4">Orders</h5>
                <ParaDescriptor>
                  All orders status.{" "}
                </ParaDescriptor>
              </CardPanelContainer>
            </Link>
          </div>
        </div>
      </div>
    </PreviewContainer>
  </PendingPreviewContainer>
  )
};

export default withRouter(connect(null, null)(PendingPreview));
