import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import {
  fetchSelectedGiftUrlStart,
  fetchTemplateDataStart,
  setDestinationPath,
} from "../../redux/user/user.actions";
import { MessagePara, SelectButton } from "./ecard.styles";
import { withRouter } from "react-router-dom";

import "./ecard.styles.css";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  selectSelectedGiftUrl,
  selectTemplateData,
} from "../../redux/user/user.selectors";
import { localError, localInfo } from "../../utils/constants";
import { useRef } from "react";
import { compose } from "redux";
import { memo } from "react";

const ECardPage = (props) => {
  const messagePart = useRef(null);

  const [selectedEcardUrl, setSelectedEcardUrl] = useState(
    `${process.env.PUBLIC_URL}/templatize/Congrats-02.png`
  );

  const [ready, setReady] = useState(false);

  const [message, setMessage] = useState({
    lineOne: {
      phrase: "From: The Giver",
      style: {
        color: "#ff9900",
        textFormat: "color: #ff9900",
      },
    },
    lineTwo: {
      phrase: "To: You",
      style: {
        color: "#ff9900",
        textFormat: "color: #ff9900",
      },
    },
    lineThree: {
      phrase: "Message: Choose a wonderful gift just for you, one time only.",
      style: {
        color: "#ff9900",
        textFormat: "color: #ff9900",
      },
    },
  });

  const [giftItems, setGiftItems] = useState([
    {
      giftItemDesc:
        "NEX Coffee Canister, Airtight Stainless Steel Storage Container 22OZ with Scoop and Date Tracker, One Way Co2 Valve, Coffee Jar for Beans or Grounds, Kitchen Food, Tea, Flour, Sugar - Gray",
      giftItemUrl:
        "https://www.amazon.com/dp/B08R88TVXK/ref=cm_sw_r_apan_glt_fabc_VD0DC53Z312K7MV0K2WA?_encoding=UTF8&amp;psc=1v%20apan_glt_fabc_S00T1WK31Q6ZD789KWE6",
      giftItemSelect:
        `${process.env.PUBLIC_URL}/gift/aHR0cHMlM0ElMkYlMkZ3d3cuYW1hem9uLmNvbSUyRmRwJTJGQjA4Ujg4VFZYSyUyRnJlZiUzRGNtX3N3X3JfYXBhbl9nbHRfZmFiY19WRDBEQzUzWjMxMks3TVYwSzJXQSUzRl9lbmNvZGluZyUzRFVURjglMjZwc2MlM0QxdiUyNTIwYXBhbl9nbHRfZmFiY19TMDBUMVdLMzFRNlpENzg5S1dFNg==/a2hhaXJ1bCU0MHR1bmFiZWFyLmNvbQ==`,
      giftItemImage:
        "https://m.media-amazon.com/images/I/61qkhrOkKBS._AC_SX466_.jpg",
      claimed: false,
    },
    {
      giftItemDesc:
        "Simple Modern Water Bottle with Straw Lid Vacuum Insulated Stainless Steel Metal Thermos Reusable Leak Proof BPA-Free Flask for Gym, Travel, Sports Summit Collection 22oz, Midnight Black",
      giftItemUrl:
        "https://www.amazon.com/dp/B07HYDC6BP/ref=cm_sw_r_apan_glt_fabc_FCE7XZN5MXTPPWZBAC13",
      giftItemSelect:
        `${process.env.PUBLIC_URL}/gift/aHR0cHMlM0ElMkYlMkZ3d3cuYW1hem9uLmNvbSUyRmRwJTJGQjA3SFlEQzZCUCUyRnJlZiUzRGNtX3N3X3JfYXBhbl9nbHRfZmFiY19GQ0U3WFpONU1YVFBQV1pCQUMxMw==/a2hhaXJ1bCU0MHR1bmFiZWFyLmNvbQ==`,
      giftItemImage:
        "https://m.media-amazon.com/images/I/6132uVBLdAL._AC_SX679_.jpg",
      claimed: false,
    },
    {
      giftItemDesc:
        "Beben IPX7 Waterproof Bluetooth Earbuds, True Wireless Earbuds, 30H Cyclic Playtime Headphones with Type C Charging Case and mic, in-Ear Stereo Earphones Headset for Sport Blue",
      giftItemUrl:
        "https://www.amazon.com/Waterproof-Bluetooth-Wireless-Headphones-Earphones/dp/B09FDHHL34/ref=mp_s_a_1_16?crid=2RCY98Q7G1CC&amp;keywords=bluetooth%2Bearbuds&amp;qid=1639588708&amp;sprefix=blue%2Caps%2C286&amp;sr=8-16&amp;th=1&amp;psc=1",
      giftItemSelect:
        `${process.env.PUBLIC_URL}/gift/aHR0cHMlM0ElMkYlMkZ3d3cuYW1hem9uLmNvbSUyRldhdGVycHJvb2YtQmx1ZXRvb3RoLVdpcmVsZXNzLUhlYWRwaG9uZXMtRWFycGhvbmVzJTJGZHAlMkZCMDlGREhITDM0JTJGcmVmJTNEbXBfc19hXzFfMTYlM0ZjcmlkJTNEMlJDWTk4UTdHMUNDJTI2a2V5d29yZHMlM0RibHVldG9vdGglMjUyQmVhcmJ1ZHMlMjZxaWQlM0QxNjM5NTg4NzA4JTI2c3ByZWZpeCUzRGJsdWUlMjUyQ2FwcyUyNTJDMjg2JTI2c3IlM0Q4LTE2JTI2dGglM0QxJTI2cHNjJTNEMQ==/a2hhaXJ1bCU0MHR1bmFiZWFyLmNvbQ==`,
      giftItemImage:
        "https://m.media-amazon.com/images/I/61SaN6fbfkL._AC_SX466_.jpg",
      claimed: false,
    },
    {
      giftItemDesc:
          "If you rather not receive a gift, you can choose instead to plant a gift-tree. We will make a donation on your behalf to the rainforest restoration project in Ethiopia.",
      giftItemUrl:
          "https://onetreeplanted.org/collections/africa/products/africa",
      giftItemSelect:
          `${process.env.PUBLIC_URL}/gift/tree`,
      giftItemImage:
          "https://www.getgivit.com/templatize/plantree.png",
      claimed: false,
    },

  ]);

  const [consumed, setConsumed] = useState(false);

  const cardEffects = useCallback(() => {
    document.body.style.background = "#000";
    document.body.style.color = "#666";
    document.body.style.fontFamily = "Futura, Helvetica, Sans-serif";
    document.body.style.textAlign = "center";
    document.body.style.perspective = 1000;

    // Wait for page to load

    // Add Flip Envelope Event Handler
    document.getElementById("envelope_front").onclick = function () {
      document.getElementById("envelope_front").classList.toggle("flipped");
      document.getElementById("envelope_back").classList.toggle("flipped");
      // setTimeout(()=>{document.getElementById('envelope_front').click();}, 2000);
      setTimeout(() => {
        document.getElementById("flap_outside").click();
      }, 3000);
      setTimeout(() => {
        document.getElementById("envelope_back_outside").click();
      }, 6000);
      setTimeout(() => {
        document.getElementById("card").click();
      }, 8000);
    };

    // Add Open Envelope Event Handler
    document.getElementById("flap_outside").onclick = function () {
      document.getElementById("flap_outside").classList.toggle("open");
      document.getElementById("flap_inside").classList.toggle("open");

      // Add Remove Card Event Handler
      // This is added after "Open Envelope" so that card can't be removed
      // until the envelope has been opened
      document.getElementById("envelope_back_outside").onclick = function () {
        document.getElementById("card").classList.toggle("removed");
        return false;
      };

      return false;
    };

    // Open Card
    document.getElementById("card").onclick = function () {
      document.getElementById("card_outside_front").classList.toggle("open");
      document.getElementById("card_inside_top").classList.toggle("open");
      return false;
    };

    document.getElementById("card_inside_bottom").onclick = function (e) {
      e.preventDefault();
      return false;
    };

  }, []);

  const {
    fetchTemplateData,
    templateData,
    fetchSelectedGiftUrl,
    selectedGiftUrl,
  } = props;
  useLayoutEffect(() => {
    const {
      history,
      match: { url, path },
    } = props;
    localInfo("match.path: " + path);
    if (!history.location.state?.email) {
      localInfo("url: " + url);
      setDestinationPath(url);
      history.push({ pathname: "/recipient-is", state: { url } });
    } else {
      fetchSelectedGiftUrl({
        giver_email: decodeURIComponent(window.atob(props.match.params.email)),
        recipientemail: history.location.state.email,
        templatename: decodeURIComponent(
          window.atob(props.match.params.templatename)
        ),
      });
      fetchTemplateData({
        giver_email: decodeURIComponent(window.atob(props.match.params.email)),
        recipientemail: history.location.state.email,
        templatename: decodeURIComponent(
          window.atob(props.match.params.templatename)
        ),
      });
      if (templateData.template_data) {
        let theData = templateData?.template_data;
        theData = JSON.parse(theData);
        const { selectedEcardUrl, message, messageHtml, giftItems } = theData;

        messagePart.current.innerHTML = messageHtml;
        setSelectedEcardUrl(selectedEcardUrl);
        setMessage(message);

        const foundAt = giftItems.findIndex(
          (ele) => ele.giftItemUrl === selectedGiftUrl
        );
        let giftItemsCopy = giftItems;
          localInfo("giftItemsCopy: ", giftItemsCopy);
        if (foundAt !== -1) {
          giftItemsCopy[foundAt].claimed = true;

          setGiftItems(giftItemsCopy);
          setConsumed(false);
        } else {
          setGiftItems(giftItems);
        }

        localInfo("completed update");
        setReady(true);
      } else {
        localError("templateData not ready.");
        setReady(false);
      }
      cardEffects();
      localInfo("history.location.state.email: ", history.location.state.email);
      localInfo(
        "giver email: ",
        decodeURIComponent(window.atob(props.match.params.email))
      );
      localInfo(
        "templatename: ",
        decodeURIComponent(window.atob(props.match.params.templatename))
      );

      // let r = setInterval(()=>{
      // 	if(!processing) {
      // 		clearInterval(r);
      // 		setTimeout(()=>{document.getElementById('envelope_front').click();}, 2000);
      // 		setTimeout(()=>{document.getElementById('flap_outside').click();}, 6000);
      // 		setTimeout(()=>{document.getElementById('envelope_back_outside').click();}, 8000);
      // 		setTimeout(()=>{document.getElementById('card').click();}, 10000);
      // 	}
      // },300);
    }

  }, [
    fetchSelectedGiftUrl,
    fetchTemplateData,
    cardEffects,
    selectedGiftUrl,
    templateData,
    props,
  ]);

  const goToThisPlace = (e, index) => {
    e.preventDefault();
    window.location.href = giftItems[index].claimed
      ? ""
      : giftItems[index].giftItemSelect;
  };

  const goToAmazon = (e, index) => {
    e.preventDefault();
    window.location.href = giftItems[index].giftItemUrl;
  };

  const openWindowTo = (e, index) => {
    e.preventDefault();
    window.open(giftItems[index].giftItemUrl);
  }


  const buttonDisplayText = (claimed) =>
    claimed ? "Already Claimed" : consumed ? "xxx" : "Select";

  const toDisplay = (claimed, consumed) => {
    const foundAt = giftItems.findIndex(
      (ele) => (ele.giftItemImage === "") ? false : ele.claimed === true
    );
    if (foundAt !== -1) {
      if (claimed) { return true; }
      if (!claimed && !consumed) { return false; }
    }
    else {
      return true;
    }
  }

  return (
    <>
      <div id="envelope">
        <div id="envelope_front"></div>
        <div id="envelope_back">
          <div id="flap_outside"></div>
          <div id="flap_inside"></div>
          <div id="envelope_back_outside"></div>
          <div id="card">
            <div id="card_outside_front">
              <img className="ecard_selected" src={selectedEcardUrl} alt="" />
            </div>
            <div id="card_inside_top">
              <MessagePara id="messaging" ref={messagePart}>
                <span
                  id="lineOne"
                  className="lineOne"
                  style={message.lineOne.style}
                >
                  {message.lineOne.phrase}
                </span>
                <br />
                <span
                  id="lineTwo"
                  className="lineTwo"
                  style={message.lineTwo.style}
                >
                  {message.lineTwo.phrase}
                </span>
                <br />
                <span
                  id="lineThree"
                  className="lineThree"
                  style={message.lineThree.style}
                >
                  {message.lineThree.phrase}
                </span>
              </MessagePara>
              <table
                  style={{
                    height: "52px",
                    width: "60%",
                    borderStyle: "none",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "-5.6rem",
                    zIndex: "10",
                  }}
                  border="0"
              >
                <tbody>
                <tr>
                  <td style={{ width: "33.3333%", textAlign: "center" }}>
                      <img
                          src={giftItems[3].giftItemImage}
                          alt=""
                          width="100"
                          height="100"
                          onClick={e=>window.open(giftItems[3].giftItemSelect)}
                      />
                  </td>

                </tr>
                <tr>

                  <td
                      style={{
                        width: "33.3333%",
                        textAlign: "center",
                        fontSize: "9px",
                      }}
                  >
                    <div
                        onClick={()=>window.open("https://onetreeplanted.org/collections/africa/products/africa")}
                        style={{cursor: "pointer"}}
                    >
                        If you rather not receive a gift, you can choose instead to plant a gift-tree. We will make a donation on your behalf to the rainforest restoration project in Ethiopia.
                    </div>
                    <br />
                    <SelectButton
                        id="gift3_ama"
                        show={toDisplay(giftItems[3].claimed, consumed)}
                        disabled={giftItems[3].claimed}
                        onClick={(e) => {
                          goToThisPlace(e, 3);
                        }}
                        href={giftItems[3].giftItemSelect}
                        type="button"
                    >
                      {buttonDisplayText(giftItems[3].claimed)}
                    </SelectButton>
                  </td>
                </tr>


                </tbody>

              </table>

            </div>
            <div id="card_inside_bottom">
              {ready && <table
                style={{
                  height: "52px",
                  width: "89%",
                  borderStyle: "none",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "1.5rem",
                  zIndex: "10",
                }}
                border="0"
              >
                <tbody>
                  <tr>
                    {ready && giftItems[0].giftItemImage !== "" &&
                      <td style={{ width: "33.3333%", textAlign: "center" }}>
                        <a
                          href={giftItems[0].giftItemSelect}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={giftItems[0].giftItemImage}
                            alt=""
                            width="130"
                            height="180"
                          />
                        </a>
                      </td>
                    }
                    {ready && giftItems[1].giftItemImage !== "" &&
                      <td style={{ width: "33.3333%", textAlign: "center" }}>
                        <a
                          href={giftItems[1].giftItemSelect}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={giftItems[1].giftItemImage}
                            alt=""
                            width="130"
                            height="180"
                          />
                        </a>
                      </td>
                    }
                    {ready && giftItems[2].giftItemImage !== "" &&
                      <td style={{ width: "33.3333%", textAlign: "center" }}>
                        <a
                          href={giftItems[2].giftItemSelect}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={giftItems[2].giftItemImage}
                            alt=""
                            width="130"
                            height="180"
                          />
                        </a>
                      </td>
                    }
                  </tr>

                  <tr>
                    {ready && giftItems[0].giftItemImage !== "" &&
                      <td
                        style={{
                          width: "33.3333%",
                          textAlign: "center",
                          fontSize: "9px",
                        }}
                      >
                        <a
                          href={giftItems[0].giftItemUrl}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(e) => {
                            goToAmazon(e, 0);
                          }}
                        >
                          {giftItems[0].giftItemDesc}
                        </a>
                        <br />
                        <SelectButton
                          id="gift0_ama"
                          show={toDisplay(giftItems[0].claimed, consumed)}
                          disabled={giftItems[0].claimed}
                          onClick={(e) => {
                            goToThisPlace(e, 0);
                          }}
                          href={giftItems[0].giftItemSelect}
                          type="button"
                        >
                          {buttonDisplayText(giftItems[0].claimed)}
                        </SelectButton>
                      </td>
                    }
                    {ready && giftItems[1].giftItemImage !== "" &&
                      <td
                        style={{
                          width: "33.3333%",
                          textAlign: "center",
                          fontSize: "9px",
                        }}
                      >
                        <a
                          href={giftItems[1].giftItemUrl}
                          onClick={(e) => {
                            goToAmazon(e, 1);
                          }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {giftItems[1].giftItemDesc}
                        </a>
                        <br />
                        <SelectButton
                          id="gift1_ama"
                          show={toDisplay(giftItems[1].claimed, consumed)}
                          disabled={giftItems[1].claimed}
                          onClick={(e) => {
                            goToThisPlace(e, 1);
                          }}
                          href={giftItems[1].giftItemSelect}
                          type="button"
                        >
                          {buttonDisplayText(giftItems[1].claimed)}
                        </SelectButton>
                      </td>
                    }
                    {ready && giftItems[2].giftItemImage !== "" &&
                      <td
                        style={{
                          width: "33.3333%",
                          textAlign: "center",
                          fontSize: "9px",
                        }}
                      >
                        <a
                          href={giftItems[2].giftItemUrl}
                          onClick={(e) => {
                            goToAmazon(e, 2);
                          }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {giftItems[2].giftItemDesc}
                        </a>
                        <br />
                        <SelectButton
                          id="gift2_ama"
                          show={toDisplay(giftItems[2].claimed, consumed)}
                          disabled={giftItems[2].claimed}
                          onClick={(e) => {
                            goToThisPlace(e, 2);
                          }}
                          href={giftItems[2].giftItemSelect}
                          type="button"
                        >
                          {buttonDisplayText(giftItems[2].claimed)}
                        </SelectButton>
                      </td>
                    }
                  </tr>
                </tbody>
              </table>}
            </div>
          </div>
        </div>
      </div>

      <p>Click to begin open card animation</p>
    </>

  );
};

const mapStateToProps = createStructuredSelector({
  templateData: selectTemplateData,
  selectedGiftUrl: selectSelectedGiftUrl,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTemplateData: (payload) => dispatch(fetchTemplateDataStart(payload)),
  fetchSelectedGiftUrl: (payload) =>
    dispatch(fetchSelectedGiftUrlStart(payload)),
});

export default compose(
  memo,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ECardPage);
