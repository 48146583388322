import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import Header from "../../components/header/header.component";
import { ConfirmButtonContainer, GiftsContainer, HeaderContainer, MessageContainer, TopicContainer } from "./review-gift.styles";
import { selectedCard, selectMessage } from "../../redux/cards/cards.selectors";
import { selectAllLandingImages, selectAllNames, selectedGiftSet, selectedGiftSetError, selectProcessingStatus } from "../../redux/gift-sets/gift-sets.selectors";
import LoadingComponent from "../../components/loading/loading.component";
import { withRouter } from "react-router-dom";
import { updateCreditCardStart } from "../../redux/user/user.actions";
import "./review-gift.styles.scss";
import FooterFinal from "../footer/footer.component";

const ReviewGiftPage = ({ updateCard, giftSetError, currentUser, getSelectedCard, message, in_progress, all_images, all_names, giftSet, location: { state: { templateName } } }) => {

  const url_noimg =
    "https://click2kemah.com/directory/wp-content/uploads/2019/04/No-image-available.jpg";


  const giftTable = () => {
    const { email } = currentUser;
    return <>
      <table style={{ height: '52px', width: '63%', borderStyle: 'none', marginLeft: 'auto', marginRight: 'auto', border: '0' }}>

        <tbody>
          <tr>
            {all_images && all_images[0] &&
              <td style={{ width: '93.14px', textAlign: 'center' }}><a href={giftSet && giftSet[0]
                ? `https://www.getgivit.com/gift/${window.btoa(
                  encodeURIComponent(templateName)
                )}/${window.btoa(encodeURIComponent(giftSet[0]))}/${window.btoa(
                  encodeURIComponent(email)
                )}/${window.btoa(encodeURIComponent(all_names[0]))}`
                : "#"
              } target="_blank" rel="noreferrer"><img src={all_images && all_images[0] ? all_images[0] : url_noimg
              } alt={all_names && all_names[0] ? all_names[0] : "Unidentified Object"
              } width="80" height="80" /></a></td>

            }
            {all_images && all_images[1] &&
              <td style={{ width: '93.14px', textAlign: 'center' }}><a href={giftSet && giftSet[1]
                ? `https://www.getgivit.com/gift/${window.btoa(
                  encodeURIComponent(templateName)
                )}/${window.btoa(encodeURIComponent(giftSet[1]))}/${window.btoa(
                  encodeURIComponent(email)
                )}/${window.btoa(encodeURIComponent(all_names[1]))}`
                : "#"
              } target="_blank" rel="noreferrer"><img src={all_images && all_images[1] ? all_images[1] : url_noimg
              } alt={all_names && all_names[1] ? all_names[1] : "Unidentified Object"
              } width="80" height="80" /></a></td>
            }
            {all_images && all_images[2] &&
              <td style={{ width: '93.14px', textAlign: 'center' }}><a href={giftSet && giftSet[2]
                ? `https://www.getgivit.com/gift/${window.btoa(
                  encodeURIComponent(templateName)
                )}/${window.btoa(encodeURIComponent(giftSet[2]))}/${window.btoa(
                  encodeURIComponent(email)
                )}/${window.btoa(encodeURIComponent(all_names[2]))}`
                : "#"
              } target="_blank" rel="noreferrer"><img src={all_images && all_images[2] ? all_images[2] : url_noimg
              } alt={all_names && all_names[2] ? all_names[2] : "Unidentified Object"
              } width="80" height="80" /></a></td>
            }
          </tr>
          <tr>
            {all_names && all_names[0] &&
              <td style={{ width: '93.14px', textAlign: 'center' }}><a href={giftSet[0]
              } target="_blank" rel="noreferrer">{all_names && all_names[0] ? all_names[0] : "Unidentified Object"
                }</a></td>
            }
            {all_names && all_names[1] &&
              <td style={{ width: '93.14px', textAlign: 'center' }}><a href={giftSet[1]
              } target="_blank" rel="noreferrer">{all_names && all_names[1] ? all_names[1] : "Unidentified Object"
                }</a></td>
            }
            {all_names && all_names[2] &&
              <td style={{ width: '93.14px', textAlign: 'center' }}><a href={giftSet[2]
              } target="_blank" rel="noreferrer">{all_names && all_names[2] ? all_names[2] : "Unidentified Object"
                }</a></td>
            }
          </tr>
        </tbody>

      </table><p style={{ textAlign: 'center' }}>&nbsp;</p>

    </>;
  };


  return <React.Fragment>
    <Header titlePage={"Review your Gift"} />
    <section className="review-gift-section">
      <div className="container">
        <div className="row">
          <div className="col s12 m12 black-text text-darken-4">
            <HeaderContainer>
              Your ecard
            </HeaderContainer>

            <TopicContainer>
              <img src={getSelectedCard.cardUrl} alt="" width="368" height="308" />
            </TopicContainer>

            <HeaderContainer>
              Your Message
            </HeaderContainer>
            <TopicContainer>
              <MessageContainer>
                {message}
              </MessageContainer>
            </TopicContainer>

            <HeaderContainer>
              Your choices
            </HeaderContainer>
            <GiftsContainer>
              {(in_progress && <LoadingComponent circleGif={true} style={{marginLeft: '12em'}} />)}
              {(!in_progress && (giftSetError !== null) && giftTable())}

            </GiftsContainer>

          </div>
          <ConfirmButtonContainer>
            <Link to={{
              pathname: "created-ecard",
              state: {
                templateName
              }
            }}>
              <button
                className="btn btn-large orange btn-confirm__60a3d156 waves-effect waves-light"
                type="button"
              >
                Confirm
              </button>
            </Link>
          </ConfirmButtonContainer>
        </div>
      </div>

    </section>
    <FooterFinal />
  </React.Fragment>;

};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  getSelectedCard: selectedCard,
  message: selectMessage,
  in_progress: selectProcessingStatus,
  all_names: selectAllNames,
  all_images: selectAllLandingImages,
  giftSet: selectedGiftSet,
  giftSetError: selectedGiftSetError,
});

const mapDispatchToProps = dispatch => ({
  updateCard: payload => dispatch(updateCreditCardStart(payload))
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewGiftPage));
