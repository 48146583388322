import * as React from 'react';
import Header from "../../components/header/header.component";
import FooterFinal from "../footer/footer.component";
import {OptionHeaderContainer} from "./template.styles";
import {CopyInstructionContainer} from "./template.styles";
import {Button, Col, Row, TextInput} from "react-materialize";
import {useEffect, useRef, useState} from "react";
import { connect } from "react-redux";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser, selectSelectedGiftUrl, selectTemplateData} from "../../redux/user/user.selectors";
import {selectAllLandingImages, selectAllNames, selectedGiftSet} from "../../redux/gift-sets/gift-sets.selectors";
import {selectAllCards, selectMessage} from "../../redux/cards/cards.selectors";
import {localError, localInfo} from "../../utils/constants";
import {toast} from "react-toastify";
import gifimg from "../../assets/gif.png";
import videoimg from "../../assets/video.png";
import {Editor} from "@tinymce/tinymce-react";
import {fetchTemplateDataStart} from "../../redux/user/user.actions";

const TemplatePage = ({
                        match: {path, url},
                        getAllCards,
                        currentUser,
                        themessage,
                        fetchTemplateData,
                        templateData}) => {
  const animatedUrlTextBoxRef = useRef(null);
  const editorRef = useRef(null);
  let found = [];
  const url_noimg =
    "https://click2kemah.com/directory/wp-content/uploads/2019/04/No-image-available.jpg";

  const [messageHtml, setMessageHtml] = useState("");
  const [ready, setReady] = useState(false);
  const [templateName, setTemplateName] = useState(url.split("/")[3]);
  const [giftItems, setGiftItems] = useState([
    {
      giftItemDesc:
        "NEX Coffee Canister, Airtight Stainless Steel Storage Container 22OZ with Scoop and Date Tracker, One Way Co2 Valve, Coffee Jar for Beans or Grounds, Kitchen Food, Tea, Flour, Sugar - Gray",
      giftItemUrl:
        "https://www.amazon.com/dp/B08R88TVXK/ref=cm_sw_r_apan_glt_fabc_VD0DC53Z312K7MV0K2WA?_encoding=UTF8&amp;psc=1v%20apan_glt_fabc_S00T1WK31Q6ZD789KWE6",
      giftItemSelect:
        `${process.env.PUBLIC_URL}/gift/aHR0cHMlM0ElMkYlMkZ3d3cuYW1hem9uLmNvbSUyRmRwJTJGQjA4Ujg4VFZYSyUyRnJlZiUzRGNtX3N3X3JfYXBhbl9nbHRfZmFiY19WRDBEQzUzWjMxMks3TVYwSzJXQSUzRl9lbmNvZGluZyUzRFVURjglMjZwc2MlM0QxdiUyNTIwYXBhbl9nbHRfZmFiY19TMDBUMVdLMzFRNlpENzg5S1dFNg==/a2hhaXJ1bCU0MHR1bmFiZWFyLmNvbQ==`,
      giftItemImage:
        "https://m.media-amazon.com/images/I/61qkhrOkKBS._AC_SX466_.jpg",
      claimed: false,
    },
    {
      giftItemDesc:
        "Simple Modern Water Bottle with Straw Lid Vacuum Insulated Stainless Steel Metal Thermos Reusable Leak Proof BPA-Free Flask for Gym, Travel, Sports Summit Collection 22oz, Midnight Black",
      giftItemUrl:
        "https://www.amazon.com/dp/B07HYDC6BP/ref=cm_sw_r_apan_glt_fabc_FCE7XZN5MXTPPWZBAC13",
      giftItemSelect:
        `${process.env.PUBLIC_URL}/gift/aHR0cHMlM0ElMkYlMkZ3d3cuYW1hem9uLmNvbSUyRmRwJTJGQjA3SFlEQzZCUCUyRnJlZiUzRGNtX3N3X3JfYXBhbl9nbHRfZmFiY19GQ0U3WFpONU1YVFBQV1pCQUMxMw==/a2hhaXJ1bCU0MHR1bmFiZWFyLmNvbQ==`,
      giftItemImage:
        "https://m.media-amazon.com/images/I/6132uVBLdAL._AC_SX679_.jpg",
      claimed: false,
    },
    {
      giftItemDesc:
        "Beben IPX7 Waterproof Bluetooth Earbuds, True Wireless Earbuds, 30H Cyclic Playtime Headphones with Type C Charging Case and mic, in-Ear Stereo Earphones Headset for Sport Blue",
      giftItemUrl:
        "https://www.amazon.com/Waterproof-Bluetooth-Wireless-Headphones-Earphones/dp/B09FDHHL34/ref=mp_s_a_1_16?crid=2RCY98Q7G1CC&amp;keywords=bluetooth%2Bearbuds&amp;qid=1639588708&amp;sprefix=blue%2Caps%2C286&amp;sr=8-16&amp;th=1&amp;psc=1",
      giftItemSelect:
        `${process.env.PUBLIC_URL}/gift/aHR0cHMlM0ElMkYlMkZ3d3cuYW1hem9uLmNvbSUyRldhdGVycHJvb2YtQmx1ZXRvb3RoLVdpcmVsZXNzLUhlYWRwaG9uZXMtRWFycGhvbmVzJTJGZHAlMkZCMDlGREhITDM0JTJGcmVmJTNEbXBfc19hXzFfMTYlM0ZjcmlkJTNEMlJDWTk4UTdHMUNDJTI2a2V5d29yZHMlM0RibHVldG9vdGglMjUyQmVhcmJ1ZHMlMjZxaWQlM0QxNjM5NTg4NzA4JTI2c3ByZWZpeCUzRGJsdWUlMjUyQ2FwcyUyNTJDMjg2JTI2c3IlM0Q4LTE2JTI2dGglM0QxJTI2cHNjJTNEMQ==/a2hhaXJ1bCU0MHR1bmFiZWFyLmNvbQ==`,
      giftItemImage:
        "https://m.media-amazon.com/images/I/61SaN6fbfkL._AC_SX466_.jpg",
      claimed: false,
    },
    {
      giftItemDesc:
        "If you rather not receive a gift, you can choose instead to plant a gift-tree. We will make a donation on your behalf to the rainforest restoration project in Ethiopia.",
      giftItemUrl:
        "https://onetreeplanted.org/collections/africa/products/africa",
      giftItemSelect:
        `${process.env.PUBLIC_URL}/gift/tree`,
      giftItemImage:
        "https://www.getgivit.com/templatize/plantree.png",
      claimed: false,
    },

  ]);


  useEffect(() => {
    const { email } = currentUser;

    // was template data already loaded ?
    if((templateName !== templateData?.template_name) ||
      (templateData?.template_name === "") ||
      (templateName === '')) {
      fetchTemplateData({
        giver_email: email,
        templatename: templateName,
      });
    }
    else {
        let theData = templateData?.template_data;
        theData = JSON.parse(theData);
        const { selectedEcardUrl, message, messageHtml, giftItems } = theData;
        setTemplateName( _ => templateData.template_name);
        setGiftItems(_ => giftItems);
        localInfo(["message: ", message]);
        setMessage(_ => message);
        localInfo("completed update");
        setReady(_ => true);
    }

    return () => {
      setTemplateName(_ => '');
    }

  }, [templateData?.template_name]);
  const copyAnimatedUrl = () => {
    // saveContentAutomatic();
    animatedUrlTextBoxRef.current.select();
    animatedUrlTextBoxRef.current.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(animatedUrlTextBoxRef.current.value);
    toast.success("Copied successfully to clipboard.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      pauseOnFocusLoss: false,
    });
  };
  const copyContentToClipboard = () => {
    // saveContentAutomatic();
    const editor = window.tinymce.activeEditor;
    if (editor) {
      editor.selection.select(editor.getBody());
      document.execCommand('copy');
    }
    toast.success("Copied to Clipboard", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      pauseOnFocusLoss: false,
    });
  };

  const [message, setMessage] = useState({
    lineOne: {
      phrase: themessage,
      style: {
        color: "#ff9900",
      },
      textStyle: "color: #ff9900",
    },
    lineTwo: {
      phrase: "",
      style: {
        color: "#ff9900",
      },
      textStyle: "color: #ff9900",
    },
    lineThree: {
      phrase: "",
      style: {
        color: "#ff9900",
      },
      textStyle: "color: #ff9900",
    },
  });


  const preSelectedTemplate = () => {
    found = [];
    for (var i = 0; i < getAllCards.length; ++i) {
      found = getAllCards[i].pathSrcs.filter((item) => item.selected);
      if (found.length > 0) {
        break;
      }
    }
    if (found.length > 0) {
      return `
      <div style="text-align: center;">
<div style="background-image: url('${found[0].src
      }');background-repeat: no-repeat;background-size: 100% 100%; height: 25em"></div>
        <div style="background-image: url('https://www.getgivit.com/templatize/envelope_bgpaste.png');background-repeat: no-repeat;background-size: 100% 100%;">
        <br />
<p style="text-align: center; margin-top: 2rem;margin-left: 1rem;margin-right: 1rem; color: black;" class="messaging"><span id="lineOne" class="lineOne" style="${message.lineOne.style.textFormat
      }">${message.lineOne.phrase
      }</span><br /><span id="lineTwo" class="lineTwo" style="${message.lineTwo.style.textFormat
      }">${message.lineTwo.phrase
      }</span><br /><span id="lineThree" class="lineThree" style="${message.lineThree.style.textFormat
      }">${message.lineThree.phrase}</span></p>

    `;
    } else return ``;
  };

  const giftTable = _ => {
    const { email } = currentUser;
    return `
<h5 style="text-align: center;"><span style="color: #ff9900;">Choose from one of these gifts:</span></h5>
<table style="height: 52px; width: 93%; border-style: none; margin-left: auto; margin-right: auto;">

<tbody>
<tr>
${giftItems[0]["giftItemImage"] && `
<td style="width: 3.3333%; text-align: center;"><a href=${giftItems[0]["giftItemImage"]
      ? `https://www.getgivit.com/gift/${window.btoa(
        encodeURIComponent(templateData.template_name)
      )}/${window.btoa(encodeURIComponent("https://onetreeplanted.org/collections/africa/products/africa"))}/${window.btoa(
        encodeURIComponent(email)
      )}/${window.btoa(encodeURIComponent("Plant A Tree in Ethiopia"))}`
      : "#"
    }
       target="_blank"><img src="https://getgivit.com/templatize/plantree.png" alt="Plant a tree picture" width="150" height="150" /></a></td>

`}
    <td>
        <tr>

      ${giftItems[0]["giftItemImage"] && `
      <td style="width: 100%; text-align: center;color=black;">If you rather not receive a gift, you can choose instead to plant a tree. We will make a donation on your behalf to the rainforest restoration project in OneTreePlanted. <br /><a href="https://onetreeplanted.org/collections/africa/products/africa" target="_blank">See details.</a><br /></td>
      `}


        </tr>

        <tr>
        <td>
        <div style="display: block">
        <a id="gift1_ama" style="background: #15d798; text-align: center; text-decoration: none; border-radius: 11px; padding: 20px 45px; margin-top: 10px; color: #ffffff;font: bold 16px / 1 sans-serif" href=${giftItems[0]["giftItemImage"]
      ? `https://www.getgivit.com/gift/${window.btoa(
        encodeURIComponent(templateData.template_name)
      )}/${window.btoa(encodeURIComponent("https://onetreeplanted.org/collections/africa/products/africa"))}/${window.btoa(
        encodeURIComponent(email)
      )}/${window.btoa(encodeURIComponent("Plant A Tree in Ethiopia"))}`
      : "#"
    } type="button">Select</a>
</div>
        </td>
        </tr>

    </td>
</tr>





<tr>
${giftItems[0]["giftItemImage"] && `
<td style="width: 3.3333%; text-align: center;"><a href=${giftItems[0]["giftItemImage"]
      ? `https://www.getgivit.com/gift/${window.btoa(
        encodeURIComponent(templateData.template_name)
      )}/${window.btoa(encodeURIComponent(giftItems[0]["giftItemUrl"]))}/${window.btoa(
        encodeURIComponent(email)
      )}/${window.btoa(encodeURIComponent(giftItems[0]["giftItemDesc"]))}`
      : "#"
    } target="_blank"><img src=${giftItems[0]["giftItemImage"] ? giftItems[0]["giftItemImage"] : url_noimg
    } alt=${giftItems[0]["giftItemDesc"] ? giftItems[0]["giftItemDesc"] : "Unidentified Object"
    } width="150" height="150" /></a></td>

`}
    <td>
        <tr>

      ${giftItems[0]["giftItemDesc"] && `
      <td style="width: 100%; text-align: center; color=black">${giftItems[0]["giftItemDesc"] ? giftItems[0]["giftItemDesc"].replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "").trim().substring(0,96)+"..." : "Unidentified Object"
    }<br /><a href="${giftItems[0]["giftItemUrl"]
    }" target="_blank">See details.</a><br /></td>
      `}


        </tr>

        <tr>
        <td>
        <div style="display: block">
        <a id="gift1_ama" style="background: #15d798; text-align: center; text-decoration: none; border-radius: 11px; padding: 20px 45px; margin-top: 10px; color: #ffffff;font: bold 16px / 1 sans-serif" href=${giftItems[0]["giftItemSelect"]
      ? `https://www.getgivit.com/gift/${window.btoa(
        encodeURIComponent(templateData.template_name)
      )}/${window.btoa(encodeURIComponent(giftItems[0]["giftItemUrl"]))}/${window.btoa(
        encodeURIComponent(email)
      )}/${window.btoa(encodeURIComponent(giftItems[0]["giftItemDesc"]))}`
      : "#"
    } type="button">Select</a>
</div>
        </td>
        </tr>

    </td>
</tr>





<tr>
${giftItems[1]["giftItemDesc"] && `
<td style="width: 3.3333%; text-align: center;"><a href=${giftItems[1]["giftItemDesc"]
      ? `https://www.getgivit.com/gift/${window.btoa(
        encodeURIComponent(templateData.template_name)
      )}/${window.btoa(encodeURIComponent(giftItems[1]["giftItemUrl"]))}/${window.btoa(
        encodeURIComponent(email)
      )}/${window.btoa(encodeURIComponent(giftItems[1]["giftItemDesc"]))}`
      : "#"
    } target="_blank"><img src=${giftItems[1]["giftItemImage"] ? giftItems[1]["giftItemImage"] : url_noimg
    } alt=${giftItems[1]["giftItemDesc"] ? giftItems[1]["giftItemDesc"] : "Unidentified Object"
    } width="150" height="150" /></a></td>

`}
    <td>
        <tr>

      ${giftItems[1]["giftItemDesc"] && `
      <td style="width: 100%; text-align: center;color: black;">${giftItems[1]["giftItemDesc"] ? giftItems[1]["giftItemDesc"].replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "").trim().substring(0,96)+"..." : "Unidentified Object"
    }<br /><a href="${giftItems[1]["giftItemUrl"]
    }" target="_blank">See details.</a><br /></td>
      `}


        </tr>

        <tr>
        <td>
        <div style="display: block">
        <a id="gift1_ama" style="background: #15d798; text-align: center; text-decoration: none; border-radius: 11px; padding: 20px 45px; margin-top: 10px; color: #ffffff;font: bold 16px / 1 sans-serif" href=${giftItems[1]["giftItemSelect"]
      ? `https://www.getgivit.com/gift/${window.btoa(
        encodeURIComponent(templateData.template_name)
      )}/${window.btoa(encodeURIComponent(giftItems[1]["giftItemUrl"]))}/${window.btoa(
        encodeURIComponent(email)
      )}/${window.btoa(encodeURIComponent(giftItems[1]["giftItemDesc"]))}`
      : "#"
    } type="button">Select</a>
</div>
        </td>
        </tr>

    </td>
</tr>





<tr>
${giftItems[2]["giftItemImage"] && `
<td style="width: 3.3333%; text-align: center;"><a href=${giftItems[2]["giftItemImage"]
      ? `https://www.getgivit.com/gift/${window.btoa(
        encodeURIComponent(templateData.template_name)
      )}/${window.btoa(encodeURIComponent(giftItems[2]["giftItemUrl"]))}/${window.btoa(
        encodeURIComponent(email)
      )}/${window.btoa(encodeURIComponent(giftItems[2]["giftItemDesc"]))}`
      : "#"
    } target="_blank"><img src=${giftItems[2]["giftItemImage"] ? giftItems[2]["giftItemImage"] : url_noimg
    } alt=${giftItems[2]["giftItemDesc"] ? giftItems[2]["giftItemDesc"] : "Unidentified Object"
    } width="150" height="150" /></a></td>

`}
    <td>
        <tr>

      ${giftItems[2]["giftItemDesc"] && `
      <td style="width: 100%; text-align: center;">${giftItems[2]["giftItemDesc"] ? giftItems[2]["giftItemDesc"].replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "").trim().substring(0,96)+"..." : "Unidentified Object"
    }<br /><a href="${giftItems[2]["giftItemUrl"]
    }" target="_blank">See details.</a><br /></td>
      `}


        </tr>

        <tr>
        <td>
        <div style="display: block">
        <a id="gift1_ama" style="background: #15d798; text-align: center; text-decoration: none; border-radius: 11px; padding: 20px 45px; margin-top: 10px; color: #ffffff;font: bold 16px / 1 sans-serif" href=${giftItems[2]["giftItemSelect"]
      ? `https://www.getgivit.com/gift/${window.btoa(
        encodeURIComponent(templateData.template_name)
      )}/${window.btoa(encodeURIComponent(giftItems[2]["giftItemUrl"]))}/${window.btoa(
        encodeURIComponent(email)
      )}/${window.btoa(encodeURIComponent(giftItems[2]["giftItemDesc"]))}`
      : "#"
    } type="button">Select</a>
</div>
        </td>
        </tr>

    </td>
</tr>


</tbody>

</table><p style="text-align: center;">&nbsp;</p>
</div>
</div>

      `;
  };


  const handleEditorChange = (e) => { };
  const onEditorContentChange = (content) => {
    let container = document.createElement("div");
    container.innerHTML = content;
    let messageElement = container?.getElementsByClassName("messaging")[0];
    setMessageHtml((_) => messageElement.innerHTML);
  };


  const initTemplate = () => {
    return `${preSelectedTemplate()}${giftTable()}`;
  };

    return (<>
      {ready && <div className="ecard-selected">
        <Header titlePage={"Your ecard"}/>
        <section className="created-ecard-section">
          <div className="container black-text">
            <div className="row">
              <div className="col s12 m6 black-text text-darken-4">
                <OptionHeaderContainer>
                  <h2>
                    <b>Option #1</b>
                  </h2>
                </OptionHeaderContainer>
                <CopyInstructionContainer>
                  <h4 style={{
                    margin: 'auto 0rem',
                  }}><b>Copy and share this</b></h4>
                </CopyInstructionContainer>

                <Row>
                  <Col
                    style={{
                      float: "none",
                      width: "280px",
                      margin: "0 auto",
                      display: "flex",
                      borderRadius: '10rem',
                      justifyContent: 'center',
                    }}
                    className=""
                    xs={12}

                  >
                    <TextInput
                      id="animated-version"
                      ref={animatedUrlTextBoxRef}
                      defaultValue={`https://getgivit.com/ecard/${window.btoa(
                        encodeURIComponent(currentUser.email)
                      )}/${window.btoa(encodeURIComponent(templateData.template_name))}`}
                      style={{
                        border: 'solid',
                        borderColor: 'purple',
                        padding: '1rem',
                        borderRadius: '3rem',
                        textAlign: 'center',
                        width: '100%',
                        justifyContent: 'center',
                        marginLeft: '-1rem',
                      }}
                    />
                  </Col>

                </Row>

                <Row>
                  <Col
                    style={{
                      float: "none",
                      width: "280px",
                    }}
                    xs={12}
                  >
                  </Col>
                  <Col
                    style={{
                      float: "none",
                      width: "280px",
                      margin: "0 auto",
                      display: "flex",
                      borderRadius: '10rem',
                      justifyContent: 'center',
                    }}
                    className=""
                    xs={12}

                  >
                    <Button
                      node="a"
                      small
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        zIndex: "999",
                        backgroundColor: 'orange',
                        fontWeight: 'bolder',
                        width: '40%',
                        fontSize: '19px'
                      }}
                      waves="light"
                      onClick={copyAnimatedUrl}
                    >
                      <b>Copy</b>
                    </Button>
                  </Col>

                </Row>

                <Row>
                  <Col
                    style={{
                      float: "none",
                      width: "280px",
                      margin: "0 auto",
                      display: "flex",
                      borderRadius: '10rem',
                      justifyContent: 'center',
                    }}
                    className=""
                    xs={12}

                  >
                    <img src={gifimg} alt=""
                         style={{width: 'fit-content', height: 'fit-content', margin: 'auto 7rem'}}/>
                  </Col>

                </Row>

              </div>
              <div className="col s12 m6 black-text text-darken-4">

                <OptionHeaderContainer>
                  <h2>
                    <b>Option #2</b>
                  </h2>
                </OptionHeaderContainer>
                <CopyInstructionContainer>
                  <h4><b>Copy rich text format to paste directly to your email</b></h4>
                </CopyInstructionContainer>
                <Row>
                  <Col
                    style={{
                      float: "none",
                      width: "280px",
                    }}
                    xs={12}
                  >
                  </Col>
                  <Col
                    style={{
                      float: "none",
                      width: "280px",
                      margin: "0 auto",
                      display: "flex",
                      borderRadius: '10rem',
                      justifyContent: 'center',
                    }}
                    className=""
                    xs={12}

                  >
                    <Button
                      node="a"
                      small
                      style={{
                        textAlign: "center",
                        marginTop: "25px",
                        zIndex: "999",
                        backgroundColor: 'orange',
                        fontWeight: 'bolder',
                        width: '40%',
                        fontSize: '19px'
                      }}
                      waves="light"
                      onClick={copyContentToClipboard}
                    >
                      <b>Copy</b>
                    </Button>
                  </Col>

                </Row>

                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  apiKey={process.env.REACT_APP_EDITOR_API_KEY}
                  initialValue={initTemplate()}
                  onEditorChange={onEditorContentChange}
                  init={{
                    custom_ui_selector: ".my-custom-button",
                    height: 500,
                    width: 500,
                    branding: false,
                    menubar: false,
                    toolbar: false,
                    disabled: true,
                    inline: true,
                    plugins: [
                      "code advlist autolink lists link image",
                      "charmap print anchor",
                      "searchreplace visualblocks",
                      "media table",
                      "autolink lists media table code",
                    ],
                    automatic_uploads: true,
                  }}
                  onChange={handleEditorChange}
                />


              </div>
            </div>
            <div className="row">
              <div className="col s12 m12 purple-text text-darken-4">
                <OptionHeaderContainer>
                  <h2>

                    <b>How to send your ecard?</b>
                  </h2>
                  <img src={videoimg} alt="" style={{width: 'fit-content', height: 'fit-content'}}/>
                </OptionHeaderContainer>
              </div>
            </div>

          </div>
        </section>
        <FooterFinal/>
      </div>}
      </>
    );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  all_names: selectAllNames,
  all_images: selectAllLandingImages,
  getAllCards: selectAllCards,
  giftSet: selectedGiftSet,
  themessage: selectMessage,
  templateData: selectTemplateData,
});

const mapDispatchToProps = dispatch => ({
  fetchTemplateData: (payload) => dispatch(fetchTemplateDataStart(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplatePage);
