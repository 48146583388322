import Header from "../../components/header/header.component";
import {withRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import OneGiftSet from "../../components/one-gift-set/one-gift-set.component";
import { createStructuredSelector } from "reselect";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  selectAmazonAccount,
  selectCurrentUser,
  selectDestinationPath,
} from "../../redux/user/user.selectors";
import {
  getGiftsImagesTextStart,
  insertGiftSetByOrderStart,
  loadGiftSetsStart, setGiftSet,
} from "../../redux/gift-sets/gift-sets.actions";
import {
  getAmazonAccountStart,
  getGiftTemplateArrayStart,
  insertUserStart,
  resetDestinationPath,
} from "../../redux/user/user.actions";
import {
  selectAllGifts,
  selectedGiftSet,
} from "../../redux/gift-sets/gift-sets.selectors";
import {
  MainContainer,
  NextButton,
  TopicContainer,
} from "./giver-selection.styles";
import FooterFinal from "../footer/footer.component";
import { compose } from "redux";
import CreditCardModalComponent from "./credit-card-modal.component";
import {InputUrlBox} from "../../components/UI/GiftUrlInput/GiftUrlInput.styles";
import useGiftsetForm from "../../utils/useGiftsetForm";
import GiftUrlInputContainer from "../../components/UI/GiftUrlInput/GiftUrlInputContainer";

const GiverSelection = ({updateGiftSet, history, selectAllGifts, insertGiftSetByOrder, currentUser, selectDestPath, resetDestPath, loadGiftSetsStart}) => {
  const { handleChange, inputs, setInputs } = useGiftsetForm({
    ["0"]: {
      selected: true,
      gifts: {
        ...selectAllGifts,
      }
    }
  }, selectAllGifts, updateGiftSet);
  const [ss, setSS] = useState({
    ...selectAllGifts,
  });
  const inputUrlHandleChange = e => {
    e.preventDefault();
    handleChange(e);
    setSS((prevState) => {
      const u = {
        ...selectAllGifts,
        [e.target.name]: e.target.value,
      };
      updateGiftSet(u); // sets to the store upon click or change
      // insertGiftSetByOrder({ arr: [inputs["0"]], setIndex: 0, currentUser });
      return u;
    });

  }

  useEffect(() => {

    (async function() {

      await loadGiftSetsStart(currentUser);
      await setSS(_ => {
        const u = {
          ...selectAllGifts,
        };
        // insertGiftSetByOrder({ arr: [inputs["0"]], setIndex: 0, currentUser });
        return u;
      });
    })();

    if (selectDestPath !== "/") {
      history.push(selectDestPath);
      resetDestPath();
    }

  }, []);

  const provideInputBoxValue = propName => {
    return selectAllGifts[propName];
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fields = e.target.querySelectorAll('.input-field input')
    fields.forEach(element => {
      element.click()

    });
    // get items if find .danger class at each element
    const errors = e.target.querySelectorAll('.input-field input.danger')

    // if errors length greater than 0,
    if ((errors.length > 0) === false) {

      await insertGiftSetByOrder({ arr: [inputs["0"]], setIndex: 0, currentUser });

      // true: go to 'Choose Ecard'
      history.push("/user/choose-ecard");

    } else {
      // false: Show message
      toast.error(
        "You must enter a valid Amazon.com product URL for each of the input boxes above.",
        {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        }
      );
    }
  };
  return (
    <React.Fragment>
      <div className="giver-selection">
        <Header titlePage={"Give"} />
        <section className="section section-gifts">
          <div className="container">
            <div className="row text-center">
              <CreditCardModalComponent />
              <MainContainer className="card-panel hoverable" style={{ margin: '0 auto', maxWidth: '600px' }}>
                <TopicContainer>
                  <h4>What would you give?</h4>
                  <span>
                    Enter the gift links (i.e. Amazon URLs to the item to
                    purchase) and confirm.{" "}
                  </span>

                </TopicContainer>
                <form method="post" onSubmit={handleSubmit} className="giver-selection-form">
                  <GiftUrlInputContainer>

                    { _ => Object.keys(selectAllGifts).map((ele, index) => {
                      // console.log("selectAllGifts: ", selectAllGifts["giftChoice_0_1"]);
                      if(index === 3) return;
                      return <>
                    <div className='input-field'>
                      <div style={{ color: "#5938BA" }}>
                        <i className="material-icons prefix" style={{ paddingLeft: "1rem" }}>card_giftcard</i>
                      </div>
                      {<InputUrlBox
                        key={ele}
                        type="url"
                        name={ele}
                        value={selectAllGifts[ele]}
                        onChange={inputUrlHandleChange}
                        onClick={inputUrlHandleChange}
                        placeholder={'Copy and paste an Amazon.com product url'}
                        label={'Url of Gift Choice'}
                      />}
                    </div>
                    </>;} )}
                  </GiftUrlInputContainer>
                  <NextButton
                    className="btn btn-large waves-effect waves-light white-text"
                    type="submit"
                  >
                    Next
                  </NextButton>
                </form>
              </MainContainer>




            </div>
          </div>
        </section>
      </div>
      <FooterFinal />
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  selectDestPath: selectDestinationPath,
  selectAllGifts: selectAllGifts,
  giftSet: selectedGiftSet,
  amazonAccount: selectAmazonAccount,
});

const mapDispatchToProps = (dispatch) => ({
  insertGiftSetByOrder: (giftSetData) =>
    dispatch(insertGiftSetByOrderStart(giftSetData)),
  updateGiftSet: (giftSet) => dispatch(setGiftSet(giftSet)),
  resetDestPath: () => dispatch(resetDestinationPath()),
  loadGiftSetsStart: (currentUser) => dispatch(loadGiftSetsStart(currentUser)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GiverSelection);
