import { UsersContainer } from "./users.styles";
import { Dropdown, Button, Icon, Modal, Row, Col } from "react-materialize";
import InviteUserButton from "../invite-user/invite-user.component";
import EditUserButton from "../edit-user/edit-user.component";

const Users = props => {

  return <>
    <section className="section section-pending-list">
      <UsersContainer className="container">
        <div className="row">
          <div className="col s12 m12 l12">
            <div className="card-panel hoverable grey lighten-4 grey-text text-darken-3 z-depth-0">
              <Row>
                <Col
                  s={7}
                >
                  <h4>User Accounts</h4>
                </Col>
                <Col
                  s={5}
                >
                  <span>
                  </span>
                </Col>
              </Row>

              <br />
              <form>
                <table className="highlight responsive-table">
                  <thead>
                    <tr>
                      <th style={{ width: "5rem" }}>User</th>
                      <th style={{ width: "10rem" }}>Roles</th>
                      <th style={{ width: "1rem" }}>&nbsp;</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>_____</td>
                      <td>Owner</td>
                      <td>
                      </td>
                    </tr>

                  </tbody>
                </table>
                <br />
              </form>
            </div>
          </div>
        </div>
      </UsersContainer>
    </section>

  </>;
};



export default Users;
