const INITIAL_STATE = {
    env: process.env.NODE_ENV,
    API_BASE_URL: "http://localhost:3001",
    LOCALHOST: "http://localhost:3001",
    PRODUCTION_API_URL: "https://getgivit.com/api"
}

const apiReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state;
    }
}

export default apiReducer;
