import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL, localError, localInfo } from '../../utils/constants';
import { store } from '../../redux/store';
import CryptoJS from 'crypto-js';

import {
  verifyAmazonSuccess,
  resetCodeStart,
  resetCodeSuccess,
  resetCodeFailure,
  getPendingRecipientsSuccess,
  getPendingRecipientsFailure,
  verifyAmazonAccountAvailableSuccess,
  verifyAmazonAccountAvailableFailure,
  getAmazonAccountSuccess,
  getAmazonAccountFailure,
  saveTemplateSuccess,
  saveTemplateFailure,
  getGiftTemplateArraySuccess,
  getGiftTemplateArrayFailure,
  getGiftTemplateArrayStart,
  fetchGiftHtmlTemplateSuccess,
  fetchGiftHtmlTemplateFailure,
  setAmazonEmailSuccess,
  setAmazonEmailFailure,
  setAmazonPkeSuccess,
  setAmazonPkeFailure,
  updateCreditCardSuccess,
  updateCreditCardFailed,
  updateTemplateDataSuccess,
  updateTemplateDataFailed,
  fetchTemplateDataSuccess,
  fetchTemplateDataFailed,
  fetchSelectedGiftUrlFailed,
  fetchSelectedGiftUrlSuccess,
  fetchAllTemplatesFailure,
  fetchAllTemplatesSuccess,
  updateActiveEcardFailure,
  updateActiveEcardSuccess,
  deleteRecipientByCreatedIdSuccess,
  deleteRecipientByCreatedIdFailure,
  processStart,
  getPendingRecipientsStart,
  processEnd,
  setDestinationPath,
  signOutStart,
  fetchCCInfoStart,
  fetchCCInfoFailure,
  fetchCCInfoSuccess,
  fetchOrdersFailure,
  fetchOrdersSuccess,
  getRecipientsByTemplatenameSuccess,
  getRecipientsByTemplatenameFailed,
  getRecipientsByTemplatenameStart
} from './user.actions';
import { isProduction } from '../../utils/constants';
import M from 'materialize-css/dist/js/materialize.min.js';
import { auth } from '../../firebase/firebase.utils';


export const getPendingRecipients = async (payload) => {
  const { idToken, email } = payload;
  const thePayload = {
    giverEmail: email
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    },
    // withCredentials: process.env.NODE_ENV === 'production' ? true : false
  };
  try {
    await axios.post(`${API_BASE_URL}/post/pending-recipients`, thePayload, options)
      .then(res => {
        const { data: { msg: { arr }, success } } = res;
        if (success) {
          store.dispatch(getPendingRecipientsSuccess(arr));
        }
      }, error => {
        store.dispatch(getPendingRecipientsFailure(error));
        localError(`Not able to retrieve data: ${error.message}`);
        if (error.response.status === 401) {
          store.dispatch(signOutStart());
        }
      });
  } catch (error) {
    store.dispatch(getPendingRecipientsFailure(error));
    localError(`Not able to retrieve data: ${error.message}`);
    if (error.response.status === 401) {
      store.dispatch(signOutStart());
    }
  }

}

export const getRecipientsByTemplatename = async (payload) => {
  const { currentUser: {idToken, email}, template_name } = payload;
  const thePayload = {
    giverEmail: email,
    template_name,
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    },
    // withCredentials: process.env.NODE_ENV === 'production' ? true : false
  };
  try {
    await axios.post(`${API_BASE_URL}/post/pending-recipients`, thePayload, options)
        .then(res => {
          const { data: { msg: { arr }, success } } = res;
          if (success) {
            store.dispatch(getRecipientsByTemplatenameSuccess(arr));
          }
        }, error => {
          store.dispatch(getRecipientsByTemplatenameFailed(error));
          localError(`Not able to retrieve data: ${error.message}`);
          if (error.response.status === 401) {
            store.dispatch(signOutStart());
          }
        });
  } catch (error) {
    store.dispatch(getRecipientsByTemplatenameFailed(error));
    localError(`Not able to retrieve data: ${error.message}`);
    if (error.response.status === 401) {
      store.dispatch(signOutStart());
    }
  }

};

export const verifyAmazonAccountAvailable = async (payload) => {
  const { idToken, email } = payload;
  const thePayload = {
    email
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };

  try {
    await axios.post(`${API_BASE_URL}/post/get-amazon-account`, thePayload, options)
      .then(res => {
        if (!isProduction()) { console.log("get amazon account RESPONSE: ", res) }
        if (res.data.success) {
          store.dispatch(verifyAmazonAccountAvailableSuccess())
        }
        else {
          store.dispatch(verifyAmazonAccountAvailableFailure(res.data.message))
        }

      }, error => {
        store.dispatch(verifyAmazonAccountAvailableFailure(error));
        localError(`Not able to retrieve data: ${error.message}`);
        if (error.response.status === 401) {
          store.dispatch(signOutStart());
        }
      });

  } catch (error) {
    store.dispatch(verifyAmazonAccountAvailableFailure(error));
    localError(`Not able to retrieve data: ${error.message}`);
    if (error.response.status === 401) {
      store.dispatch(signOutStart());
    }
  }
}

export const getAmazonAccountService = async (payload) => {

  const { idToken, email } = payload;
  const thePayload = {
    email
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };
  try {
    await axios.post(`${API_BASE_URL}/post/get-amazon-account-all`, thePayload, options)
      .then(res => {
        if (!isProduction()) { console.log("get amazon account RESPONSE: ", res) }
        if (res.data.success) {
          store.dispatch(getAmazonAccountSuccess(res.data.data))
        }
        else {
          store.dispatch(getAmazonAccountFailure(res.data.message))
        }

      }, error => {
        console.log("ERROR: ", error);
        store.dispatch(getAmazonAccountFailure(error));
        if (error.response.status === 401) {
          store.dispatch(signOutStart());
        }
      });

  } catch (error) {
    localError(`Not able to retrieve data: ${error.message}`);
    if (error.response.status === 401) {
      store.dispatch(signOutStart());
    }
  }

}

export const resetCode = async (payload) => {
  const { idToken, email } = payload;
  const thePayload = {
    email
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    },
    // withCredentials: process.env.NODE_ENV === 'production' ? true : false
  };

  try {
    await axios.post(`${API_BASE_URL}/post/reset-code`, thePayload, options)
      .then(res => {
        if (!isProduction()) { console.log("resetCode RESPONSE: ", res) }
        store.dispatch(resetCodeSuccess())
      }, error => {
        localInfo("Error: ", error);
        store.dispatch(resetCodeFailure(error));
        if (error.response.status === 401) {
          store.dispatch(signOutStart());
        }
      });
  } catch (error) {
    localError(`Not able to perform action: ${error.message}`);
    if (error.response.status === 401) {
      store.dispatch(signOutStart());
    }
  }
};

export const sendOtpAsync = async (payload, onProgress) => {

  const { code, currentUser: { idToken, email } } = payload;
  const thePayload = {
    code,
    userEmail: btoa(email)
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    },
    // withCredentials: process.env.NODE_ENV === 'production' ? true:false
  };
  try {
    await axios.post(`${API_BASE_URL}/post/set-otp`, thePayload, options)
      .then(res => {
        if (!isProduction()) { console.log("sendOtpAsync RESPONSE: ", res) }
      }, error => {
        localError("Error: ", error);
        if (error.response.status === 401) {
          store.dispatch(signOutStart());
        }
      });
  } catch (error) {
    localError(`Not able to perform action: ${error.message}`);
    if (error.response.status === 401) {
      store.dispatch(signOutStart());
    }
  }

};

export const insertUser = async (payload, onProgress) => {
  const thePayload = {
    currentUser: payload
  };
  const { idToken } = payload;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    },
    // withCredentials: process.env.NODE_ENV === 'production' ? true:false
  };
  await axios.post(`${API_BASE_URL}/post/set-currentuser`, thePayload, options)
    .then(res => {
      if (!isProduction()) { console.log("insertUser RESPONSE: ", res); }
    }, error => {
      if (!isProduction()) { console.log("Error: ", error); }
    });
};

const housekeeping = (currentUser) => {
  // to dispatch reset of user code to '' at backend
  if (currentUser) {
    store.dispatch(resetCodeStart(currentUser));
    store.dispatch(verifyAmazonSuccess());
  }
  toast.dismiss();
};

export const executePurchase = async (payload) => {
  const { currentUser: { idToken, email }, recipients, executionIdentifier } = payload;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    },
    // withCredentials: process.env.NODE_ENV === 'production' ? true:false
  };

  const finalPayload = {
    currentUser: payload.currentUser,
    recipients,
    executionIdentifier
  };

  localInfo("finalPayload:", finalPayload);

  try {
    axios.post(`${API_BASE_URL}/post/purchasexe`, finalPayload, options)
      .then(res => {
        localInfo(`RESPONSE: ${res}`);
      }, error => {
        localError(`ERROR: ${error}`);
      });
  } catch (error) {
    localError(`Was not able to execute purchase: ${error.message}`);
  }

};

export const verifyAmazon = async (payload) => {
  const { amazonEmail, amazonPassword, idToken, email } = payload;
  const theBody = {
    amaUser: amazonEmail,
    amaPass: amazonPassword,
    amaType: '',            // amazonAccountType,
    email
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    },
    // withCredentials: process.env.NODE_ENV === 'production' ? true:false
  };
  axios.post(`${API_BASE_URL}/post/verifyama`, theBody, options)
    .then(result => {
      if (!isProduction()) { console.log("RESULT: ", result); }
      if (result.data.status.result === 'OK') {
        M.Toast.dismissAll();
        toast.success("Amazon login verified.", {
          position: "bottom-left",
          hideProgressBar: false,
          onClose: () => housekeeping(payload)
        });
      }
      else {
        toast.error("Amazon login failed. Check your username/password and try again.", {
          position: "bottom-left",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => housekeeping(payload)
        });
      }

    }, error => {
      toast.error("Verification Failed. Cannot verify your account.", {
        position: "bottom-left",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => housekeeping(payload)
      });
    });
};

export const getGiftTemplateService = async (payload) => {
  const { idToken, email } = payload;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };
  axios.post(`${API_BASE_URL}/post/get-template-array`, { email }, options)
    .then(res => {
      if (!isProduction()) { console.log("POST get-template-array response: ", res); }
      if (res.data.success) {
        if (!isProduction()) { console.log("SUCCESS: get-template-array res: ", res); }
        store.dispatch(getGiftTemplateArraySuccess(res.data.result));
      }
      else {
        if (!isProduction()) { console.log("FAILURE: get-template-array res: ", res); }
        store.dispatch(getGiftTemplateArrayFailure(res.data.msg));
      }
    }, error => {
      if (!isProduction()) { console.log("ERROR get-template-array: ", error); }
      store.dispatch(getGiftTemplateArrayFailure(error));
    });
};

export const saveTemplateService = async (payload) => {
  const { idToken, email, template_html, template_name } = payload;
  const thePayload = {
    email,
    template_html,
    template_name
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };
  axios.post(`${API_BASE_URL}/post/save-template`, thePayload, options)
    .then(res => {
      if (!isProduction()) { console.log("POST save-template response: ", res); }
      if (res.data.success) {
        if (!isProduction()) { console.log("SUCCESS: save-template res: ", res); }
        store.dispatch(saveTemplateSuccess());
        store.dispatch(getGiftTemplateArrayStart({ email, idToken }));
      }
      else {
        if (!isProduction()) { console.log("FAILURE: save-template res: ", res); }
        store.dispatch(saveTemplateFailure(res.data.msg));
      }
    }, error => {
      if (!isProduction()) { console.log("ERROR save-template: ", error); }
      store.dispatch(saveTemplateFailure(error));
    });
};


export const fetchGiftHtmlTemplateService = (payload) => {
  const { idToken, email } = payload;
  const thePayload = {
    email
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };
  axios.post(`${API_BASE_URL}/post/get-html-content`, thePayload, options)
    .then(res => {
      if (!isProduction()) { console.log("POST get-html-content response: ", res); }
      if (res.data.success) {
        if (!isProduction()) { console.log("SUCCESS: get-html-content res: ", res); }
        store.dispatch(fetchGiftHtmlTemplateSuccess(res.data.result));
      }
      else {
        if (!isProduction()) { console.log("FAILURE: get-html-content res: ", res); }
        store.dispatch(fetchGiftHtmlTemplateFailure(res.data.msg));
      }
    }, error => {
      if (!isProduction()) { console.log("ERROR get-html-content: ", error); }
      store.dispatch(fetchGiftHtmlTemplateFailure(error));
    });
};

export const saveAmazonEmailService = payload => {
  const { idToken, email, amazonEmail } = payload;
  const thePayload = {
    email,
    amazonEmail
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };
  axios.post(`${API_BASE_URL}/post/set-amazon-email`, thePayload, options)
    .then(res => {
      if (!isProduction()) { console.log("POST set-amazon-email response: ", res); }
      if (res.data.success) {
        if (!isProduction()) { console.log("SUCCESS: set-amazon-email res: ", res); }
        store.dispatch(setAmazonEmailSuccess());
      }
      else {
        if (!isProduction()) { console.log("FAILURE: set-amazon-email res: ", res); }
        store.dispatch(setAmazonEmailFailure(res));
      }
    }, error => {
      if (!isProduction()) { console.log("ERROR set-amazon-email: ", error); }
      store.dispatch(setAmazonEmailFailure(error));
    });
};

export const saveAmazonPkeService = payload => {
  const { idToken, email, amazonPassword } = payload;
  const thePayload = {
    email,
    amazonPassword
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };
  axios.post(`${API_BASE_URL}/post/set-amazon-password`, thePayload, options)
    .then(res => {
      if (!isProduction()) { console.log("POST set-amazon-password response: ", res); }
      if (res.data.success) {
        if (!isProduction()) { console.log("SUCCESS: set-amazon-password res: ", res); }
        store.dispatch(setAmazonPkeSuccess());
      }
      else {
        if (!isProduction()) { console.log("FAILURE: set-amazon-password res: ", res); }
        store.dispatch(setAmazonPkeFailure(res));
      }
    }, error => {
      if (!isProduction()) { console.log("ERROR set-amazon-password: ", error); }
      store.dispatch(setAmazonPkeFailure(error));
    });

};


export const updateCreditCardService = async (payload) => {
  const { idToken, email, creditCard } = payload;
  const thePayload0 = {
    email: window.btoa(email)
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };

  // axios.post(`${API_BASE_URL}/post/tell-me`, thePayload0, options)
  //   .then(res => {
  //     if (!isProduction()) { console.log("POST tell-me response: ", res); }
  //     if (res.data.success) {
  //       if (!isProduction()) {
  //         console.log("SUCCESS: tell-me response: ", window.atob(res.data.msg));
  //       }

  //       const thePayload1 = {
  //         data: CryptoJS.AES.encrypt(JSON.stringify({ email, creditCard }), window.atob(res.data.msg)).toString(),
  //         email
  //       };

  //       // console.log("data encrypted: ", thePayload1.data);
  //       // console.log("window.atob(res.data.msg): ", window.atob(res.data.msg));
  //       // const bytesDecrypted = CryptoJS.AES.decrypt(thePayload1.data, window.atob(res.data.msg));
  //       // console.log("bytes: ", bytesDecrypted);
  //       // const decryptedData = JSON.parse(bytesDecrypted.toString(CryptoJS.enc.Utf8));
  //       // console.log("data decrypted: ", decryptedData);
  //       // console.log("decryptedData: ", window.atob(decryptedData.creditCard));
  //       // console.log("cc data: ", JSON.parse(window.atob(decryptedData.creditCard)));

  //       axios.post(`${API_BASE_URL}/post/update-creditcard`, thePayload1, options)
  //         .then(res => {
  //           if (!isProduction()) { console.log("POST update-creditcard response: ", res); }
  //           if (res.data.success) {
  //             if (!isProduction()) { console.log("SUCCESS: update-creditcard response: ", res); }
  //             store.dispatch(updateCreditCardSuccess());
  //             toast.dismiss();
  //             toast.success('Card update successful.', {
  //               position: "bottom-center",
  //               autoClose: 5000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //               progress: undefined,
  //             });
  //           }
  //           else {
  //             if (!isProduction()) { console.log("FAILURE: update-creditcard response: ", res); }
  //             store.dispatch(updateCreditCardFailed(res));
  //             toast.error('Card update failed. Please try again later or try again with another card.', {
  //               position: "bottom-center",
  //               autoClose: 5000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //               progress: undefined,
  //             });
  //           }
  //         }, error => {
  //           if (!isProduction()) { console.log("ERROR update-creditcard response: ", error); }
  //           store.dispatch(updateCreditCardFailed(error));
  //           toast.error('Card update failed. Please try again later or try again with another card.', {
  //             position: "bottom-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           });
  //         });
  //     }
  //     else {
  //       if (!isProduction()) { console.log("FAILURE: tell-me response: ", res); }
  //       store.dispatch(updateCreditCardFailed(res));
  //     }
  //   }, error => {
  //     if (!isProduction()) { console.log("ERROR tell-me response: ", error); }
  //     store.dispatch(updateCreditCardFailed(error));
  //   });

  const resp = await axios.post(`${API_BASE_URL}/post/tell-me`, thePayload0, options);

  if (resp.data.success) {
    localInfo("SUCCESS: tell-me response: ", window.atob(resp.data.msg));

    const thePayload1 = {
      data: CryptoJS.AES.encrypt(JSON.stringify({ email, creditCard }), window.atob(resp.data.msg)).toString(),
      email
    };

    const resp2 = await axios.post(`${API_BASE_URL}/post/update-creditcard`, thePayload1, options);

    localInfo("POST update-creditcard response: ", resp2);
    if (resp2.data.success) {
      localInfo("SUCCESS: update-creditcard response: ", resp2);
      store.dispatch(updateCreditCardSuccess());
      toast.dismiss();
      toast.success('Card update successful.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else {
      localInfo("FAILURE: update-creditcard response: ", resp2);
      store.dispatch(updateCreditCardFailed(resp2));
      toast.error('Card update failed. Please try again later or try again with another card.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }
  else {
    localInfo("FAILURE: tell-me response: ", resp);
    store.dispatch(updateCreditCardFailed(resp));
  }

};

export const updateTemplateDataService = payload => {
  const { idToken } = payload;

  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };

  axios.post(`${API_BASE_URL}/post/update-templateauto`, payload, options)
    .then(res => {
      if (!isProduction()) { console.log("POST update-templateauto response: ", res); }
      if (res.data.success) {
        if (!isProduction()) { console.log("SUCCESS: update-templateauto response: ", res); }
        store.dispatch(updateTemplateDataSuccess());
      }
      else {
        if (!isProduction()) { console.log("FAILURE: update-templateauto response: ", res); }
        store.dispatch(updateTemplateDataFailed(res));
      }
    }, error => {
      if (!isProduction()) { console.log("ERROR update-templateauto response: ", error); }
      store.dispatch(updateTemplateDataFailed(error));
    });
};

export const fetchTemplateDataService = payload => {

  axios.post(`${API_BASE_URL}/post/template-data`, payload)
    .then(res => {
      if (!isProduction()) { console.log("POST template-data response: ", res); }
      if (res.data.success) {
        if (!isProduction()) { console.log("SUCCESS: template-data response: ", res); }
        store.dispatch(fetchTemplateDataSuccess(res.data.result[0]));
      }
      else {
        if (!isProduction()) { console.log("FAILURE: template-data response: ", res); }
        store.dispatch(fetchTemplateDataFailed(res));
      }
    }, error => {
      if (!isProduction()) { console.log("ERROR template-data response: ", error); }
      store.dispatch(fetchTemplateDataFailed(error));
    });

};

export const fetchSelectedGiftUrlService = payload => {
  axios.post(`${API_BASE_URL}/post/selected-gift-url`, payload)
    .then(res => {
      if (!isProduction()) { console.log("POST selected-gift-url response: ", res); }
      if (res.data.success) {
        if (!isProduction()) { console.log("SUCCESS: selected-gift-url response: ", res); }
        store.dispatch(fetchSelectedGiftUrlSuccess(res.data.data));
      }
      else {
        if (!isProduction()) { console.log("FAILURE: selected-gift-url response: ", res); }
        store.dispatch(fetchSelectedGiftUrlFailed(res));
      }
    }, error => {
      if (!isProduction()) { console.log("ERROR selected-gift-url response: ", error); }
      store.dispatch(fetchSelectedGiftUrlFailed(error));
    });

};

export const fetchAllTemplatesService = async (payload) => {
  await axios.post(`${API_BASE_URL}/post/all-templates`, payload)
    .then(res => {
      if (!isProduction()) { console.log("POST all-templates response: ", res); }
      if (res.data.success) {
        if (!isProduction()) { console.log("SUCCESS: all-templates response: ", res); }
        // store.dispatch(fetchSelectedGiftUrlSuccess(res.data.data));
        store.dispatch(fetchAllTemplatesSuccess(res.data.result));
      }
      else {
        if (!isProduction()) { console.log("FAILURE: all-templates response: ", res); }
        store.dispatch(fetchAllTemplatesFailure(res));
      }
    }, error => {
      if (!isProduction()) { console.log("ERROR all-templates response: ", error); }
      store.dispatch(fetchAllTemplatesFailure(error));
    });

};

export const updateActiveEcardService = async payload => {
  await axios.post(`${API_BASE_URL}/post/update-active-ecard`, payload)
    .then(res => {
      if (!isProduction()) { console.log("POST update-active-ecard response: ", res); }
      if (res.data.success) {
        if (!isProduction()) { console.log("SUCCESS: update-active-ecard response: ", res); }
        store.dispatch(updateActiveEcardSuccess(res.data.result));
      }
      else {
        if (!isProduction()) { console.log("FAILURE: update-active-ecard response: ", res); }
        store.dispatch(updateActiveEcardFailure(res));
      }
    }, error => {
      if (!isProduction()) { console.log("ERROR update-active-ecard response: ", error); }
      store.dispatch(updateActiveEcardFailure(error));
    });

};

export const deleteRecipientByCreatedIdService = async (payload) => {
  store.dispatch(processStart());
  const { currentUser: { idToken } } = payload;

  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };
  try {
    await axios.post(`${API_BASE_URL}/post/delete-recipient-by-created-id`, payload, options)
      .then(async (res) => {
        if (!isProduction()) { console.log("POST delete-recipient-by-created-id response: ", res); }
        if (res.data.success) {
          if (!isProduction()) { console.log("SUCCESS: delete-recipient-by-created-id response: ", res); }
          store.dispatch(deleteRecipientByCreatedIdSuccess(res.data.result));
        }
        else {
          if (!isProduction()) { console.log("FAILURE: delete-recipient-by-created-id response: ", res); }
          store.dispatch(deleteRecipientByCreatedIdFailure(res));
        }
        store.dispatch(getPendingRecipientsStart(payload.currentUser));
        store.dispatch(processEnd());
      }, error => {
        if (!isProduction()) { console.log("ERROR delete-recipient-by-created-id response: ", error); }
        store.dispatch(deleteRecipientByCreatedIdFailure(error));
      });

  } catch (error) {
    throw new Error(error.message);
  }


};

export const updateApprovedRecipientService = async (payload) => {
  localInfo("Payload /post/update-approved-recipient: ", payload);
  const { currentUser: { idToken }, template_name } = payload;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };

  try {
    await axios.post(`${API_BASE_URL}/post/update-approved-recipient`, payload, options)
      .then(res => {
        localInfo("Response from /post/update-approved-recipient:", res);
        store.dispatch(getRecipientsByTemplatenameStart({currentUser: payload.currentUser, template_name}));
      });

  } catch (error) {
    localError(`Error on updating on checked / approve recipient: ${error}`);
  }
};

export const fetchCCInfoService = async (payload) => {
  localInfo("Payload /post/ccinfo: ", payload);
  const { idToken } = payload;
  localInfo("idToken: ", idToken);
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };
  try {
    await axios.post(`${API_BASE_URL}/post/ccinfo`, payload, options)
      .then(res => {
        if (!isProduction()) {
          console.log("Response from /post/ccinfo:", res);
        }
        if (!res.data.success) {
          return store.dispatch(fetchCCInfoFailure(res.data.message));
        }
        return store.dispatch(fetchCCInfoSuccess(res.data.message));
      });

  } catch (error) {
    localError(`Error on getting the cc info: ${error}`);
  }

};


export const fetchOrdersService = async (payload) => {
  const { currentUser: { idToken } } = payload;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Type firebase Token ${idToken}`,
      'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
    }
  };

  try {
    await axios.post(`${API_BASE_URL}/post/orders`, payload, options)
      .then(res => {
        if (!isProduction()) {
          console.log("Response from /post/orders:", res);
        }
        if (!res.data.success) {
          return store.dispatch(fetchOrdersFailure(res.data.message));
        }
        return store.dispatch(fetchOrdersSuccess(res.data.message));
      });

  } catch (error) {
    localError(`Error on fetching the orders: ${error}`);
  }

};

export const logoutService = async () => {
  localInfo("Starting to logout with server");
};

export const loginService = async () => {
  localInfo("Starting to logIN with server");
  try {
    fetch(`http://localhost:3001/user`, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': process.env.REACT_APP_ALLOW_ORIGINS
      },
    })
      .then(response => response.json())
      .then(response => {
        console.log("RESPONSE: ", response);
        this.setState(
          {
            body: response
          })
      });

  } catch (error) {
    console.log(error.message);
  }
};
