import Header from '../../components/header/header.component';
import { CardPanelContainer } from './ama-creds.styles';
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectCurrentUser, selectPendingRecipients } from '../../redux/user/user.selectors';
import { executePurchaseStart, getPendingRecipientsSuccess, resetAuthAttempt, resetCodeStart, setAmazonEmail, setAmazonPke, setDestinationPath } from '../../redux/user/user.actions';
import { createStructuredSelector } from 'reselect';
import { TextInput } from 'react-materialize';
import FooterFinal from '../footer/footer.component';
import { localInfo } from '../../utils/constants';
import useSocNsRm from '../../hooks/useSocNsRm';
import {doNothingOnReturnKey} from "../../utils/keyCapture";

const AmazonCredentialsPage = (props) => {
  const { currentUser, startPurchase, location: { state: { recipients } } } = props;
  const [theRecipients, setTheRecipients] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const currentRoom = `user-${currentUser.id}`;
  const [socket, joinTheRoom, leaveTheRoom, sendData] = useSocNsRm(currentRoom, "/purchasing")

  useEffect(() => {
    const { pendingRecipients, resetAuthAttempt } = props;
    resetAuthAttempt();
    if (currentUser) {
      localInfo("pendingRecipients: ", recipients);
      setTheRecipients(recipients);

      // const executionIdentifier = window.localStorage.getItem("eid") ?? Math.random() * 9099;
      // joinTheRoom(currentRoom, socket);
      // sendData("prep-driver", { currentUser, executionIdentifier }, socket);
    }
  }, [currentUser]);

  const handleChange = event => {
    const { value, name } = event.target;
    const { setAmazonEmail, setAmazonPassword } = props;
    const { idToken, email } = currentUser;
    switch (name) {
      case 'email':
        setEmail(value);
        setAmazonEmail({ amazonEmail: value, idToken, email });
        break;
      case 'password':
        setPassword(value);
        setAmazonPassword({ amazonPassword: value, idToken, email });
        break;
      default:
        console.error('Something went wrong!')
        break;
    }
  };

  const updateRecipientsAndResetOtpCode = _ => {
    const { resetCode, setPendingRecipients } = props;
    setPendingRecipients(theRecipients);
    resetCode(currentUser);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    joinTheRoom(currentRoom, socket);
    const { history } = props;
    updateRecipientsAndResetOtpCode();
    const executionIdentifier = window.localStorage.getItem("eid") ?? Math.random() * 9099;
    // await sendData("prep-driver", { currentUser, executionIdentifier }, socket);
    window.localStorage.setItem("eid", executionIdentifier);

    // await sendData("buy", { currentUser, recipients: theRecipients, executionIdentifier }, socket);
    await startPurchase({ currentUser, recipients: theRecipients, executionIdentifier });
    history.push("/giver");
  };

  const handleOnKeyPress = async event => {
    doNothingOnReturnKey(event);
    await updateRecipientsAndResetOtpCode();
  };

  return <>
    <Header titlePage={"Amazon Login"} />
    <section className="section section-login">
      <div className="container">
        <div className="row">
          <div className="col s12 m8 offset-m2 l6 offset-l3">
            <div className="card-panel hoverable blue darken-2">
              <CardPanelContainer>
                <h4>Sign in with Amazon</h4>
                <span>Specify your Amazon email and password</span>
                <form
                  onSubmit={handleSubmit}
                  onKeyPress={handleOnKeyPress}
                >
                  <div className="input-field">
                    {/* <i className="material-icons prefix">email</i> */}
                    <TextInput
                      icon="email"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      value={email}
                      placeholder="Email"
                      required
                    />
                    {/* <label htmlFor="email" className="white-text">Amazon Email</label> */}
                  </div>

                  <div className="input-field">
                    {/* <i className="material-icons prefix">lock</i> */}
                    <TextInput
                      icon="lock"
                      placeholder="Password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={handleChange}
                      required
                    />
                    {/* <label htmlFor="password" className="white-text">Amazon Password</label> */}
                  </div>
                  <input
                    type="submit"
                    value="Proceed"
                    className="btn btn-large blue btn-extended__ae140c51 white-text"
                  />
                </form>
              </CardPanelContainer>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FooterFinal />
  </>;
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  pendingRecipients: selectPendingRecipients,
});


const mapDispatchToProps = dispatch => ({
  setDestinationPath: path => dispatch(setDestinationPath(path)),
  setAmazonEmail: email => dispatch(setAmazonEmail(email)),
  setAmazonPassword: psw => dispatch(setAmazonPke(psw)),
  resetCode: user => dispatch(resetCodeStart(user)),
  setPendingRecipients: recipients => dispatch(getPendingRecipientsSuccess(recipients)),
  resetAuthAttempt: _ => dispatch(resetAuthAttempt()),
  startPurchase: user => dispatch(executePurchaseStart(user)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AmazonCredentialsPage));
