

const SocialMediaFooter = _ => (
  <div><b style={{
    marginLeft: '6rem',
    fontWeight: 'bolder'
  }}>SOCIAL MEDIA is under construction</b><div className="" style={{ display: 'flex', justifyContent: 'space-around', color: '#ffffff', margin: '1rem' }}><a href="https://www.facebook.com/lazygiver" target="_new" style={{ color: 'white' }}><i className="fab fa-facebook"></i></a>
      <a href="https://twitter.com/Lazy_giver" target="_new" style={{ color: 'white' }}><i className="fab fa-twitter"></i></a><a href="https://www.instagram.com/lazygiver/" target="_new" style={{ color: 'white' }}><i className="fab fa-instagram"></i>
      </a><a href="https://www.tiktok.com/@lazygiver" target="_new" style={{ color: 'white' }}><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="tiktok" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-tiktok fa-w-14 fa-sm"><path fill="currentColor" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" className=""></path></svg></a></div></div>
);

export default SocialMediaFooter;
